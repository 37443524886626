import {
  feedbackFormGrey,
  helpQuestionBlue,
  whiteCategoriesButtonPressed,
} from "../../constants/colors";

const style = {
  chips: {
    display: "flex",
    flexFlow: "row wrap",
    margin: "2% 0%",
  },
  chip: {
    backgroundColor: "transparent",
    border: "1px solid #5631E9",
    color: helpQuestionBlue,
  },
  modalWrapper: {
    borderRadius:36,
  },
  modalWrapperMobile: {
    height: "75vh",
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    left: "0",
    padding: "5%",
    borderRadius: 0,
    top: "50%",
    position: "sticky",
    overflow: "hidden",
  },
  modalWrapperMobileFocused: {
    height: "84vh",
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    left: "0",
    padding: "5%",
    borderRadius: 0,
    inset: 0,
    marginBottom: "0%",
    overflow: "hidden",
  },
  labelTextStyle: {
    fontVariant: "all-small-caps",
    fontSize: 16,
    fontWeight: 400,
    color: feedbackFormGrey,
  },
  flexRowDirectionStyle: {
    fontVariant: "normal",
    display: "flex",
    flexDirection: "row",
  },
  inputStyle: {
    border: "none",
    outline: "none",
    color: helpQuestionBlue,
    padding: "1.8% 1vw",
    width: "80%",
    backgroundColor: whiteCategoriesButtonPressed,
    boxShadow:
      "inset 4px 0px 29px rgba(255, 255, 255, 0.6), inset 0px 4px 10px #ADB9CE",
    borderRadius: 4,
  },
  inputStyleMobile: {
    border: "none",
    outline: "none",
    color: helpQuestionBlue,
    padding: "1.8%",
    width: "95%",
    backgroundColor: whiteCategoriesButtonPressed,
    boxShadow:
      "inset 4px 0px 29px rgba(255, 255, 255, 0.6), inset 0px 4px 10px #ADB9CE",
    borderRadius: 4,
  },
  buttonStyle: {
    border: "none",
    outline: "none",
    padding: "2.7%",
    marginLeft: "-1%",
    fontSize: 14,
    width: "20%",
    color: helpQuestionBlue,
    backgroundColor: whiteCategoriesButtonPressed,
    boxShadow:
      "inset 4px 0px 29px rgba(255, 255, 255, 0.6), inset 0px 4px 10px #ADB9CE",
    borderRadius: 4,
  },
  buttonStyleMobile: {
    border: "none",
    outline: "none",
    padding: "2.7%",
    marginLeft: "-1%",
    fontSize: 14,
    width: "30%",
    color: helpQuestionBlue,
    backgroundColor: whiteCategoriesButtonPressed,
    boxShadow:
      "inset 4px 0px 29px rgba(255, 255, 255, 0.6), inset 0px 4px 10px #ADB9CE",
    borderRadius: 4,
  },
  iconStyle: {
    alignContent: "center",
    marginLeft: "17%",
    marginRight: "5%",
  },
  cardHeader: {
    marginTop: "3%",
    fontFamily: "Source Sans Pro",
  },
  chipsDiv: {
    height: "45%",
  },
  actionDiv: {
    height: "20%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  actionButtonStyle: {
    marginLeft: "80%",
    width: "20%",
  },
  actionButtonStyleMobile: {
    alignSelf: "flex-end",
    width: "100%",
  },
  modalLineStyle: {
    display: "none",
  },
  modalLineStyleMobile: {
    display: "block",
    width: "10%",
    position: "absolute",
    top: "2%",
    left: "42%",
  },
};

export default style;
