import React from "react";
import cancelIcon from "../../assets/icons/cancel.svg";
import blueCancelIcon from "../../assets/icons/blueCancel.svg";

const CloseButton = ({ setOpen, style }) =>
    <img
        onClick={() => setOpen()}
        src={cancelIcon}
        onMouseEnter={e => e.target.src = blueCancelIcon}
        onMouseLeave={e => e.target.src = cancelIcon}
        alt='close'
        style={style}
    />

export default CloseButton