import React from "react";
import ModalPopupCard from "../modal-popup-card/ModalPopupCard";
import { Formik, Form } from "formik";
import CardHeaderText from "../card-header-text/CardHeaderText";
import ActionButton from "../action-button/ActionButton";
import LabelFunctionality from "../label-functionality-component/LabelFunctionality";
import styles from "./Style-FeedbackRespond.js";
import logo from "../../assets/icons/logo.svg";
import Hr from "../hr/hr";
import {mobileQuery, useMediaQuery} from "../../helpers/mediaQuery"
import "../../styles/PlaceholderAnimation.css";
import { ErrorMessage } from "../../helpers/field-error/FieldError"

const appName = "Feedback.Exchange";

const FeedbackRespond = (props) => {
  let isMobile = useMediaQuery(mobileQuery);
  let isErrorInEmail=true;
  let isTouchedEmail=false;
  return (
    <ModalPopupCard
      customStyles={isMobile?styles.modalMobile:styles.modal}
      isModalVisible={props.isOpen}
      setOpen={props.setOpen}
    >
      <Formik
        initialValues={{
          email: "",
        }}
        
        validate={values => {
          let errors = {'email':'', 'valid': true};
          if (!values.email) {
            errors.email = "Required";
            errors.valid = false;
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email) 
      
          ) {
            errors.email = "Invalid email address";
            errors.valid = false;
          }
          return errors;
        }}
        
        render={( {
            values,
            touched,
            errors,
            handleChange,
            handleBlur
           }) => (
          
          <Form style={!isMobile?styles.wrapper:styles.wrapperMobile}>
            
            <CardHeaderText
              customStyle={styles.cardHeaderTextStyle}
            >
              Thank you for your feedback
            </CardHeaderText>
            <LabelFunctionality
              textlabel={
                "Enter your email to give this person a chance to respond to your feedback. They won't see your email address and you'll remain anonymous."
              }
            >
            
              <input
                className="animation"
                style={!isMobile?styles.inputStyle:styles.inputStyleMobile}
                name="email"
                type="email"
                placeholder="Your email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
                {isErrorInEmail= touched.email && !errors.valid}
                {isTouchedEmail = touched.email}
              <ErrorMessage 
                message={errors.email}
                touched={touched.email}
              />    
            
            </LabelFunctionality>
            {!isMobile?<Hr/>:null}
            <div style={!isMobile?styles.links:styles.linksMobile}>
              <a href="https://feedback.exchange/app" style={{fontWeight: "bold"}}>Need a feedback?</a>
              <span>
                <img src={logo} alt="Logo App" style={styles.logoPic}/>
                <a href="https://feedback.exchange/">{appName}</a>
              </span>
            </div>
            <ActionButton
              customStyles={!isMobile?styles.actionButton:styles.actionButtonMobile}
              buttonText={"SEND"}
              type="submit"
              onPress={() => {
                  if(!isErrorInEmail && isTouchedEmail === true){
                    props.changeSignedBy(values.email);
                    props.confirmation();
                    props.submitForm();
                    props.setOpen(false);
                  }
              }}
            />
          </Form>
        )}
      />

    </ModalPopupCard>

  );

};
export default FeedbackRespond;
