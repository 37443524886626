import React, { useEffect} from "react";
import CardHeaderText from "../../../../components/card-header-text/CardHeaderText";
import styles from "./Style_BlogTileBig";
import { mobileQuery, smallRes, useMediaQuery } from "../../../../helpers/mediaQuery";
import Writer from "../Writer";

const BlogTileBig = ({ blogPost, writerImg, tileImage }) => {
    const { title, date, text, writerName, writerProfession } = blogPost;
    let isMobile = useMediaQuery(mobileQuery);
    let isSmallRes = useMediaQuery(smallRes);
    let contentStyle;  
    if(isMobile)  { contentStyle = styles.contentMobile; }
    else if(isSmallRes) { contentStyle = { ...styles.content, ...styles.contentSmallRes }; }
    else  contentStyle = styles.content;
return (
    <div style={isSmallRes ? styles.smallResWrapper : styles.wrapper}>
        <div style={{...{backgroundImage:`url(${tileImage})`}, ...styles.image}}></div>
        <div style={contentStyle}>
            <CardHeaderText customStyle={isMobile ? styles.mobileTitle : styles.title}>
                {title}
            </CardHeaderText>
            <span style={styles.date}>{date}</span>
            <div style={isMobile ? styles.mobileText : styles.text}>
                {text}
            </div>
            <Writer 
                writerImg={writerImg} 
                writerName={writerName} 
                writerProfession={writerProfession}
            />
        </div>
    </div>
);
};

export default BlogTileBig;
