const StyleSocialMediaComponent = {
  img: {
    width: "2.0rem",
    height: "2.0rem",
  },
  link: {
    width: "2.0rem",
    height: "2.0rem",
    marginRight: "1.5rem",
  },
  flexRowDirectionStyleSocial: {
    display: "flex",
    flexDirection: "row",
    marginTop: "-10%",
  },
  flexRowDirectionStyleSocialMobile: {
    display: "flex",
    flexDirection: "row",
  }
};
export default StyleSocialMediaComponent;
