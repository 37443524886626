import React from "react";
import modalStyle from "../modal-popup-card/Style_ModalPopupCard"
import { lineGrey } from "../../constants/colors";

const paddingFactor = parseFloat(modalStyle.wrapper.content.padding)

const styles = {
    line: {
        width: `${100 + paddingFactor * 6 - 0.5}%`,
        position: "relative",
        right: `${paddingFactor * 3 - 0.25}%`,
        height: 1,
        borderColor: lineGrey,
    }
}

const fn = () => <hr style={styles.line} />
export default fn;