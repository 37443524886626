import React from "react";
import Header from "../../components/header-component/Header";
import PageOuterWrapper from "../../components/page-outer-wrapper/PageOuterWrapper";
import BlogSingleWrapper from "../../pagesContentWrappers/blog-wrapper/blog-single/BlogSingleWrapper";
import styles from "./Style_Blog";
import { mobileQuery, useMediaQuery } from "../../helpers/mediaQuery";

const Blog = () => {
  let isMobile = useMediaQuery(mobileQuery);
    return (
      <PageOuterWrapper>
          <div style={isMobile? styles.blogMobile : styles.blog}>
            <Header/>
            <BlogSingleWrapper/>  
          </div>
      </PageOuterWrapper>
    );
  };
  export default Blog;
