import {
  white,
  helpQuestionBlue,
  whiteCategoriesButtonPressed,
  feedbackFormGrey,
} from "../../constants/colors";

const styles = {
  wrapper: {
    minHeight: "76%",
    margin: "auto",
    backgroundColor: white,
    borderRadius: 36,
    boxShadow: "-16px -15px 36px #FFFFFF, 0px 14px 34px #D4DDED",
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-between",
    padding: "3.33% 4%",
  },
  wrapperMobile: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-between",
    padding: "5%",
  },
  wrapperText: {
    height: "8.7vh",
    minHeight: '8.7vh',
    width: "100%",
    backgroundColor: helpQuestionBlue,
    opacity: 0.5,
    borderRadius: "9px",
    fontSize: "0.8125em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding:"5%"
  },
  mobileWrapperText:{
    height: "10vh",
  },
  cardHeaderText: {
    fontFamily: "Source Sans Pro",
    color: white,
    fontSize: "2.2em",
  },
  cardHeaderMobile: {
    fontSize: "1.6em",
  },
  inputStyle: {
    border: "none",
    outline: "none",
    color: helpQuestionBlue,
    padding: "1.8% 1vw",
    width: "82%",
    backgroundColor: whiteCategoriesButtonPressed,
    boxShadow:
      "inset 4px 0px 29px rgba(255, 255, 255, 0.6), inset 0px 4px 10px #ADB9CE",
    borderRadius: 4,
  },
  labelStyle: {
    marginTop: "3%",
    justifyContent: "left",
  },
  labelStyleMobile: {
    justifyContent: "left",
  },
  tooltipIcon: {
    alignSelf:"center",
    marginTop:"-4px",
    marginLeft: "5px",
  },
  buttonStyle: {
    border: "none",
    outline: "none",
    padding: "2%",
    marginLeft: "-1%",
    fontSize: 14,
    width: "20%",
    color: helpQuestionBlue,
    backgroundColor: whiteCategoriesButtonPressed,
    boxShadow:
      "inset 4px 0px 29px rgba(255, 255, 255, 0.6), inset 0px 4px 10px #ADB9CE",
    borderRadius: 4,
  },
  buttonStyleMobile: {
    border: "none",
    outline: "none",
    padding: "5%",
    marginLeft: "-1%",
    fontSize: 14,
    width: "30%",
    color: helpQuestionBlue,
    backgroundColor: whiteCategoriesButtonPressed,
    boxShadow:
      "inset 4px 0px 29px rgba(255, 255, 255, 0.6), inset 0px 4px 10px #ADB9CE",
    borderRadius: 4,
  },
  flexRowDirectionStyle: {
    display: "flex",
    flexDirection: "row",
  },
  iconStyle: {
    alignContent: "center",
    marginLeft: "17%",
    paddingRight: '10%'
  },
  iconSuccessStyle: {
    marginRight: "5%",
    width: "8.5%",
  },
  text: {
    fontFamily: 'Source Sans Pro',
    fontSize: '1.45em',
  },
  socialMediaAndQrContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "3%"
  },
  qrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  qrContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  qr: {
    width: "8rem",
    height: "8rem",
    marginLeft:"10%"
  },
  download: {
    fontSize: "1.3em",
    alignSelf: "flex-end",
    fontVariant: "all-small-caps",
    lineHeight: "1.25",
    marginBottom: "0.75vh",
    color: feedbackFormGrey
  }
};
export default styles;
