import React from "react";
import { NavLink, Route, Switch } from "react-router-dom";
import MotionDiv from "../../../components/motion-div/MotionDiv";
import styles from "./Style_BlogSingleWrapper";
import wrapperStyle from "../../../styles/Wrapper";
import back from "../assets/icons/arrowBack.svg";
import BlogShare from "../components/BlogShare";
import ArticleOne from "../articles/ArticleOne";
import ArticleTwo from "../articles/ArticleTwo";
import { mobileQuery, useMediaQuery } from "../../../helpers/mediaQuery";

const BlogSingleWrapper = () => {
let isMobile = useMediaQuery(mobileQuery);
return (
    <MotionDiv 
        customStyles=
            {isMobile ? styles.wrapperMobile : 
            {...wrapperStyle.wrapper, ...styles.wrapper}}
    >
        {!isMobile && <BlogShare/>}
        <NavLink to="/blog" style={styles.back}>
            <img src={back} style={styles.backImg}/>
            Back to Blog
        </NavLink>
        <Switch>
            <Route path="/blog/why-feedback-is-important" component={ArticleOne} />
            <Route path="/blog/the-art-of-feedback" component={ArticleTwo} />
        </Switch>
    </MotionDiv>
    );
};

export default BlogSingleWrapper;