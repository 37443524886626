import React from "react"
import { NavLink } from "react-router-dom"
import ModalPopupCard from "../modal-popup-card/ModalPopupCard"
import ActionButton from "../action-button/ActionButton"
import styles from "./Style_GivingFeedbackConfirmation"
import CardHeaderText from "../card-header-text/CardHeaderText"
import Hr from "../hr/hr"
import { useMediaQuery, mobileQuery } from "../../helpers/mediaQuery"

const GivingFeedbackConfirmation=(props)=>{
    let isMobile=useMediaQuery(mobileQuery)

    return(
        <ModalPopupCard 
            isModalVisible={props.isOpen} 
            setOpen={props.setOpen} 
            customStyles={isMobile ? styles.modalContentMobile : styles.modalContent} 
        >
            <CardHeaderText
                customStyle={isMobile ? {margin:"2% 0"} : null}
            >
                Your feedback has been sent to {props.userName.toLowerCase()}
            </CardHeaderText>
            <p 
                style={isMobile ? {margin:"2% 0"} : null }
            >
                Thank You for giving feedback. Your input will help them develop their skills and keep growing.
            </p>
            <NavLink to="/">
                <p style={styles.link}>Need a feedback? Start creating your own feedback page now.</p>
            </NavLink>
            {!isMobile?<Hr/>:null}
            <a 
                style={!isMobile?null:styles.buttonMobile} 
                href="https://feedback.exchange/"
            >
               <ActionButton
                    customStyles={!isMobile?styles.button:styles.buttonMobile} 
                    buttonText="Learn more about Feedback Exchange"
                >
               </ActionButton>
            </a>
        </ModalPopupCard>
    )
}

export default GivingFeedbackConfirmation