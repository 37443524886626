import { darkGrey } from "../../constants/colors";

const styles = {
  txtStyle: {
    fontFamily: "Source Sans Pro",
    color: darkGrey,
  },
  label: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "5px",
  },
  title: {
    lineHeight: 2,
    color: darkGrey,
    letterSpacing:"0.04em",
    fontVariant:"all-small-caps",
    fontSize:"1.25em"
  },
  child: {
    width: "100%",
  },
};

export default styles;
