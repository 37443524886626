// DEPENDENCIES
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// STYLE AND STYLE DEPENDENCIES
import styles from "./Style_ReadingFeedbackWrapper";
import "../../styles/mobileStyle.css";
// COMPONENTS
import MotionDiv from "../../components/motion-div/MotionDiv"; 
import ReadingFeedbackCards from "../../components/reading-feedback-cards/ReadingFeedbackCards";
import FeedbackDropdownMenu from "../../components/feedback-dropdown-menu/FeedbackDropdownMenu";
import Chip from "../../components/chip/Chip";
import CardHeaderText from "../../components/card-header-text/CardHeaderText";
// HELPERS
import APIHelper from "../../helpers/API";
import { mobileQuery, smallDesktop, useMediaQuery } from "../../helpers/mediaQuery";
import firebase from "../../helpers/firebaseAnalytics";

/**
 *
 * @param feedbacksUrl will be passed from routing
 * NOTE:This is how we will call it in App.js
 * <Router>
 * <Route path = "/:feedbacksUrl" component={ReadingFeedbackPage}>
 * </Router>
 *
 * useParams{} does not work if that component is not in the route
 */
const ReadingFeedbackWrapper = (props) => {
  const [feedbackDoc, setData] = useState("");
  const { feedbacksUrl } = useParams("");
  const [menuSelect, setMenuSelect] = useState([]);
  const [userUrls, setUserUrls] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  let isMobile = useMediaQuery(mobileQuery);
  let isSmallDesktop = useMediaQuery(smallDesktop);


  const removeEntry = (index) => {
    let array = menuSelect.slice();
    array.splice(index, 1);
    setMenuSelect(array);
  };

  useEffect(() => {
    APIHelper.getFeedback(feedbacksUrl).then((resp) => {
      setData(resp.data);
    }).catch((error) => {
      console.log(error);
      firebase.analytics().logEvent("Error: getFeedback", {
        Component: "ReadingFeedbackWrapper",
        error: error,
      });
    });
  }, [feedbacksUrl]);
 
  useEffect(() => {
    let objects = [];
  
    if (feedbackDoc) {
      
      const feedbacksArray = feedbackDoc.map(
        feedback => feedback.feedbacks
      );
      
      const userUrls = feedbacksArray.flat().map(
        feedback => feedback.userFeedbackUrl
      );
        
      [...new Set(userUrls)].forEach((str) => {
        str && objects.push({ label: str, value: str });
      }); 
      
      setFeedbacks(feedbacksArray.flat());
      setUserUrls(objects);
      setMenuSelect([]);
    }
  }, [feedbackDoc]);

  return (
    <MotionDiv
      customStyles={{
        ...styles.wrapper, 
        ...isSmallDesktop && styles.smallDesktopWrapper,
        ...(isMobile && { minWidth: "100%", marginTop: "56px" }),
      }}
    >
    
      <CardHeaderText customStyle={isMobile ? styles.titleMobile : styles.title}>
        {feedbackDoc && `Feedback is the breakfast of champions.`}
      </CardHeaderText>
      <FeedbackDropdownMenu
        categoriesArray={userUrls}
        menuSelect={menuSelect}
        setMenuSelect={setMenuSelect}
      />
      <div style={styles.filters}>
        {menuSelect.map((filter, index) => (
          <Chip
            key={index}
            displayText={filter}
            removeChip={() => removeEntry(index)}
            customStyles={{
              ...styles.chipStyle,
              ...(isMobile ? styles.chipStyleMobile : null),
            }}
            darkIcon={true}
          />
        ))}
      </div>
      <ReadingFeedbackCards
        feedbacksArray={feedbackDoc && feedbacks}
        filter={menuSelect}
        feedbacksUrl={feedbacksUrl}
      />
    </MotionDiv>
  );
};
export default ReadingFeedbackWrapper;
