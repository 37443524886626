export const givingFeedbackStyles = {
  givingFeedback: {
    width: "75%"
  },
  givingFeedbackMobile: {
    width: "100%",
  },
  adSpace: {
    width: "12.5%",
  },
};
export default givingFeedbackStyles;
