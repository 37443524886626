import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import chevronBottom from "../../assets/icons/chevronBottom.svg";
import checkmark from "../../assets/icons/checkmark.svg";
import styles from "./StyleFeedbackDropdown";
import firebase from "../../helpers/firebaseAnalytics";

const Option = ({ children, ...props }) => (
  <components.Option {...props}>
    <span>
      {props.isSelected && (
        <img
          src={checkmark}
          alt="selected"
          style={{
            position: "absolute",
            marginTop: "2%",
            marginLeft: "1%",
          }}
        />
      )}
    </span>
    <div style={{ paddingLeft: "15%" }}>{children}</div>
  </components.Option>
);

const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div style={{ width: "60%", marginBottom: "2vh" }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Overlay onClick={onClose} /> : null}
  </div>
);

const Menu = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        zIndex: 2,
      }}
      {...props}
    />
  );
};

const Overlay = (props) => (
  <div
    style={{
      top: 0,
      left: 0,
      height: "100vh",
      width: "100%",
      position: "absolute",
      zIndex: 1,
    }}
    {...props}
  />
);

const FeedbackDropdownMenu = ({
  categoriesArray,
  menuSelect,
  setMenuSelect,
}) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(undefined);

  useEffect(() => {
    setValue(
      menuSelect.map((category) => ({ label: category, value: category }))
    );
  }, [menuSelect]);
  const analytics = firebase.analytics();

  return (
    <Dropdown
      isOpen={open}
      onClose={() => setOpen(!open)}
      target={
        <div
          style={styles.title}
          onClick={() => {
            setOpen(!open);
            analytics.logEvent("Reading Feedback DropDown Pressed");
          }}
        >
          <span>FILTER BY YOUR FEEDBACK REQUESTS</span>
          <img src={chevronBottom} alt="arrow" style={{ marginLeft: "2%" }} />
        </div>
      }
    >
      <Select
        components={{
          Control: () => null,
          Option,
        }}
        hideSelectedOptions={false}
        isMulti
        menuIsOpen
        onChange={(value) => {
          setOpen(!open);
          setValue(value);
          setMenuSelect(value.map((value) => value.value));
        }}
        options={categoriesArray}
        styles={styles.selectStyles}
        value={value}
      />
    </Dropdown>
  );
};

export default FeedbackDropdownMenu;
