import React from "react";
import { mobileQuery, useMediaQuery } from "../../../helpers/mediaQuery";


const Title = ({ children }) => {
let isMobile = useMediaQuery(mobileQuery);
  return (
    <div style={isMobile ? styles.mobileTitle : styles.title}>
      {children}
    </div>
  );
};

export default Title;

const styles = {
    title: {
        fontFamily:"Playfair Display",
        color: "black",
        lineHeight:"1.5",
        fontSize: "2.28em", 
        margin: "2vh 0 0"
    },
    mobileTitle: {
        fontFamily:"Playfair Display",
        color: "black",
        lineHeight:"1.5",
        fontSize: "1.5em", 
        margin: "2vh 0 0"
    },
}
