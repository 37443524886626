import React from "react";
import icon from "../../assets/icons/cancelWhite.svg";
import blueIcon from "../../assets/icons/blueCancel.svg";
import style from "./Style_Chip";

const Chip = ({ displayText, removeChip, customStyles, darkIcon }) => {
  return (
    <div style={{ ...style.wrapper, ...customStyles }}>
      <div style ={style.chipContent}>
        <span style={style.text}>{displayText}</span>
        <img
          onClick={removeChip}
          src={darkIcon ? blueIcon : icon}
          alt={"remove"}
          style={style.divIcon}
        />
      </div>
    </div>
  );
};
export default Chip;
