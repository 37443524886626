import dummyTile from "../assets/images/dummyTileImage.png";

export const BlogPosts = [
    { title: "Know Yourself Your Inner Power", date: "15 April, 2020", text:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vel rhoncus sem, vel feugiat lorem. Praesent eu tellus finibus, feugiat dui et, maximus nisl,.", writerName:"Jay Paul", writerProfession:"Psychotherapist", tileImage:dummyTile },
    { title: "Train Your Brain This New Years", date: "15 April, 2020", text:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vel rhoncus sem, vel feugiat lorem. Praesent eu tellus finibus, feugiat dui et, maximus nisl,.",  writerName:"Randall Carroll", writerProfession:"Psychotherapist", tileImage:dummyTile  },
    { title: "How To Stop Living Your Life On Autopilot", date: "15 April, 2020", text:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vel rhoncus sem, vel feugiat lorem. Praesent eu tellus finibus, feugiat dui et, maximus nisl,.",  writerName:"Hettie Blake", writerProfession:"Psychotherapist", tileImage:dummyTile },
    { title: "How To Stop Living Your Life On Autopilot", date: "15 April, 2020", text:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vel rhoncus sem, vel feugiat lorem. Praesent eu tellus finibus, feugiat dui et, maximus nisl,.",  writerName:"Hettie Blake", writerProfession:"Psychotherapist", tileImage:dummyTile },
    { title: "How To Stop Living Your Life On Autopilot", date: "15 April, 2020", text:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vel rhoncus sem, vel feugiat lorem. Praesent eu tellus finibus, feugiat dui et, maximus nisl,.",  writerName:"Hettie Blake", writerProfession:"Psychotherapist", tileImage:dummyTile },
    { title: "How To Stop Living Your Life On Autopilot", date: "15 April, 2020", text:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vel rhoncus sem, vel feugiat lorem. Praesent eu tellus finibus, feugiat dui et, maximus nisl,.",  writerName:"Hettie Blake", writerProfession:"Psychotherapist", tileImage:dummyTile },
    { title: "How To Stop Living Your Life On Autopilot", date: "15 April, 2020", text:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vel rhoncus sem, vel feugiat lorem. Praesent eu tellus finibus, feugiat dui et, maximus nisl,.",  writerName:"Hettie Blake", writerProfession:"Psychotherapist", tileImage:dummyTile },
    { title: "How To Stop Living Your Life On Autopilot", date: "15 April, 2020", text:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vel rhoncus sem, vel feugiat lorem. Praesent eu tellus finibus, feugiat dui et, maximus nisl,.",  writerName:"Hettie Blake", writerProfession:"Psychotherapist", tileImage:dummyTile },
    { title: "Why feedback is important", date: "15 July, 2021", text:"Thank you for the feedback is a phrase often heard these days throughout many offices big and small, and here’s why: The need for..", writerName:"Dan Redzic", writerProfession:"Project manager" },
    { title: "The art of feedback", date: "28 April, 2021", text:"Let's face it. Criticism sucks. Well, at least, for the majority of people it does. Nobody wants to hear they did a bad job after they've worked so..", writerName:"Adna Alibasic", writerProfession:"Copywriter" },

];

//returns latest two posts added to array above
let lastTwoPosts =  BlogPosts.slice(-2);

//returns all other posts ordered from newest to oldest
export let otherPosts = BlogPosts.slice(0, (BlogPosts.length-2)).reverse();

export let bigTileOne = lastTwoPosts[0];
export let bigTileTwo = lastTwoPosts[1];