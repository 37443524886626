import React, {useState, useEffect} from "react";
import Fb from "../assets/icons/fb.svg";
import Twitter from "../assets/icons/twitter.svg"
import Linkedin from "../assets/icons/linkedin.svg";
import CopyLink from "../assets/icons/copyLink.svg";
import { shareToFacebook, shareToTwitter, shareToLinkedIn, copyToClipboard } from "../../../helpers/shareToSocialMedia";

const BlogShare = () => {

  const  [articleTitle, SetArticleTitle] = useState('');

  useEffect(()=> {
    const url = window.location.href;
    SetArticleTitle("blog%2F" + url.substring(url.lastIndexOf('/') + 1))
  }, [])
  
  return (
    <div style={styles.wrapper}>
        <div style={styles.share}>
            Share
        </div>  
        <a onClick={() => shareToFacebook(articleTitle)}>
            <img  src={Fb} style={styles.img} />
        </a>
        <a onClick={() => shareToTwitter(articleTitle)}>
            <img src={Twitter} style={styles.img}/>
        </a>
        <a onClick={() => shareToLinkedIn(articleTitle)}>
            <img src={Linkedin} style={styles.img}/>
        </a>
        <a onClick={copyToClipboard}>
            <img src={CopyLink} style={styles.img}/>
        </a>
    </div>
    
  );
};

export default BlogShare;

const styles = {
    wrapper: {
      position: "absolute",
      marginTop:"5%",
      marginLeft: "-15vmin",
      display:"flex",
      flexDirection:"column"
    },
    share: {
      width:"100%",
      height:"auto",
      margin:"2vh 0",
      fontSize:"1.25em",
      margin:"1vh 0",
      color: "black",
      lineHeight:"1.5",
    },
    img: {
      margin:"1vh 0",
      marginLeft: "50%",
    }
}