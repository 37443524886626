import { helpQuestionBlue, white } from '../../constants/colors';

const styles = {
  motionDivMobile: {
    height: "100%",
    width: "100%",
    margin: 'auto',
  },
  motionDiv: {
    height: "86%",
    margin: 'auto',
  },
  wrapperMobile: {
    width: "100%",
    marginTop: "56px",
    minHeight: "80vh",
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-between",
    padding: "4% 7%",
    wordWrap: 'break-word',
  },
  categories: {
    display: "flex",
    flexWrap: "wrap",
    textAlign: "center",
    marginLeft: "-1vw",
    lineHeight: "4.5vh"
  },
  categoriesMobile: {
    display: "flex",
    flexWrap: "wrap",
    textAlign: "center",
    lineHeight: "4.5vh",
    marginBottom: "3vh",
    marginTop: "1vh",
    fontSize: "0.875em"
  },
  UserURL: {
    color: helpQuestionBlue,
    fontSize: '1rem',
    //marginBottom: '2vh',
  },
  feedback: {
    height: '67%',
    width: '100%',
    marginTop: '3%'
  },
  textBox: {
    height: '90%',
  },
  yourMessage:{
    fontSize:'1.75em',
  },
  yourMessageMobile:{
    fontSize:'1.4em',
  },
  textArea: {
    height: '95%',
    width: '100%',
    padding: "2vh 1.5vw",
    borderRadius: 18,
    outline: "none",
    border: "none",
    fontSize: '1.125em',
    resize: "none",
    overflowWrap: "break-word",
    boxShadow: "inset 4px 0px 29px rgba(255, 255, 255, 0.6), inset 0px 4px 10px #ADB9CE",
  },

  textAreaMobile: {
    height: '250px',
    width: '100%',
    padding: "1.5vh 5vw",
    borderRadius: 18,
    outline: "none",
    border: "none",
    fontSize: '1em',
    resize: "none",
    boxShadow: "inset 4px 0px 29px rgba(255, 255, 255, 0.6), inset 0px 4px 10px #ADB9CE",
  },
  anonMobile: {
    textAlign: "center",
    marginBottom: "1vh",
    display: "block",
  },
  anonMobileFocus: {
    display: "none",
  }

};

export default styles;
