import React, {useRef} from "react";
import Modal from "react-modal";
import modalStyles from "./Style_MobileMenuModal";
import CloseButton from "../close-button/CloseButton";
import '../../styles/ModalAnimation.css';
import {CSSTransition} from "react-transition-group";
/**
 * @param isModalVisible if modal is going to be displayed
 * @param children children components or elements of modal
 * @param customStyle is a paraameter that will be used to add additional style to this component
 * It will be primary used if the component needs some adjusting in another component or in a page view etc..
 */
Modal.setAppElement('#root');

const MobileModal = ({ isModalVisible, setOpen, children, customStyles, setModalVisibleHandler }) => {

  const disableScroll = () => {
    document.body.style.overflow="hidden";
  };

  const enableScroll = () => {
    document.body.style.overflow="visible";
  }
  
  const nodeRef = useRef(null);
  return (
    <CSSTransition
    in={isModalVisible}
    timeout={300}
    nodeRef = {nodeRef}
  >
    <Modal
      style={{overlay: modalStyles.wrapper.overlay, content: {...modalStyles.wrapper.content, ...customStyles}}}
      isOpen={isModalVisible}
      shouldCloseOnOverlayClick={true}
      onAfterOpen={disableScroll}
      onAfterClose={enableScroll}
      closeTimeoutMS={300}
    >
      <CloseButton
        setOpen={setOpen}
        style={modalStyles.close}
        
      />
      {children}
    </Modal>
    </CSSTransition>
  )
};
export default MobileModal;