import React, { Component } from "react";
import emailjs from "emailjs-com";
import "./styles.css";

export class Contact extends Component {
    constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      success: {display: "none"},
    };
  }
  sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_rq0410h",
        "template_8hjrkpn",
        e.target,
        "user_TOx2VklMRWLuBjG3aicor"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    this.resetForm();
  };
  resetForm = () => {
    this.setState({
      name: "",
      email: "",
      message: "",
      success: {display: "block"},
    });
    }
    handleChange = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };

    render() {
        const { name, email, message, success } = this.state;
        const { formText, buttonText, showMessage, onClick } = this.props;
    return (
        <section id="contact">
            <div className="row text-center">
              
                <form onSubmit={this.sendEmail.bind(this)} method="post" action="" id="form">
                  <h5 
                    data-sr="enter top over 1s, wait 0.3s, move 24px, reset" 
                    style= {{"paddingBottom": "20px"}} className="contact-info text-center"
                  >
                    {formText}
                  </h5>
                  <div 
                    data-sr="enter left over 1s, wait 0.3s, move 24px, reset" 
                    className={["form-group", "col-md-6"].join(' ')}
                  >
                    <input 
                      type="text" 
                      className="form-control" 
                      onChange={this.handleChange}
                      value={name}
                      id="name" 
                      name="name" 
                      placeholder="Your Name" 
                    />
                  </div>
                  <div 
                    data-sr="enter right over 1s, wait 0.3s, move 24px, reset" 
                    className={["form-group", "col-md-6"].join(' ')}
                  >
                    <input 
                      type="email" 
                      className="form-control" 
                      id="email" 
                      name="email" 
                      value={email}
                      onChange={this.handleChange}
                      placeholder="Your Email" 
                    />
                  </div>
                  <div 
                    data-sr="enter bottom over 1s, wait 0.3s, move 24px, reset" 
                    className={["form-group", "col-md-12"].join(' ')}
                  >
                { showMessage && 
                    <textarea 
                      cols="60" 
                      rows="3" 
                      id="message" 
                      name="message"
                      value={message} 
                      onChange={this.handleChange}
                      className="form-control" 
                      placeholder="Write your comment here...">
                    </textarea>
                }
                    
                  </div>
                  <div 
                    data-sr="enter bottom over 1s, wait 0.3s, move 24px, reset" 
                    className={["form-group", "col-sm-12"].join(' ')}
                  >
                    <button 
                      type="submit" 
                      className="button-leweb"
                      onClick={onClick}
                    >
                      {buttonText}
                    </button>
                  </div>
                </form>   
                <div 
                  id="success" 
                  style={success}
                  className={["col-md-12", "col-sm-12", "text-center"].join(' ')}
                >
                  <i className="fa fa-check-circle"></i>
                  <strong>Message successfully sent</strong>
                  <p>Thanks for contacting us. We will respond to you as soon as possible!</p>
                </div>							
            </div>				
        </section>
    );
}
}

export default Contact;