import {
  black,
  feedbackFormGrey,
  pillowTextBlue,
  socialMediaHeaderTextBlue,
  whiteCategoriesButtonPressed,
} from "../../constants/colors";

export const openFeedbackStyles = {
  modal: {
    marginTop: "auto",
    height: "62.5%",
    justifyContent: "flex-start",
  },
  cardHeader: {
    marginRight: "18%",
    marginBottom: "2.5%",
  },
  category: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    padding: "1% 2%",
    width: "max-content",
    minHeight: "4.5vh",
    marginBottom: "5%",
    flexShrink: 0,
    color: pillowTextBlue,
    background: whiteCategoriesButtonPressed,
    borderRadius: 34,
    boxShadow:
      "inset 1px 0px 1px rgba(200, 198, 198), inset 0px 4px 10px #ADB9CE",
    outline: "none",
    border: "none",
  },
  categoryMobile: {
    marginTop: "5%",
  },
  feedbackFromStyle: {
    marginBottom: "2.5%",
    marginTop: "2.5%",
    fontVariant: "all-small-caps",
    fontWeight: 400,
    color: feedbackFormGrey,
    fontSize: 15,
  },
  feedbackMessageStyle: {
    marginLeft: "2vw",
    overflowWrap: "break-word",
    color: black,
    marginRight: "2vw",
    wordWrap: "break-word",
    overflowY: "scroll",
    fontSize: "18px",
    padding:"4% 0",
  },
  quotationStart: {
    alignSelf: "flex-start",
  },
  quotationEnd: {
    alignSelf: "flex-end",
    marginBottom: "4%"
  },
  linkDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
  },
  linkText: {
    color: socialMediaHeaderTextBlue,
    marginLeft: "1%",
    fontWeight: 400,
    fontSize: "1.5em",
    padding:"2% 0",
    wordBreak:"break-word"
  },
  qutationStyle: {
    marginTop: "1vh",
    height: "2vh",
    width: "2vh",
  },
  usefulDiv: {
    display: "flex",
    background: "rgba(86, 49, 233, 0.4)",
    borderRadius: 9,
    padding: "10px 30px",
    alignSelf: "flex-end",
  },
  usefulText: {
    flexBasis: "70%",
    fontSize: 20,
    lineHeight: "25px",
    color: "white",
    alignSelf: "center",
  },
  usefulTextMobile: {
    fontSize: 17,
    lineHeight: "20px",
  },
  buttonBlock: {
    backgroundColor: "transparent",
    color: "white",
    height: 60,
    width: 60,
    border: "none",
    borderRadius: 9,
  },
  buttonBlockMobile: {
    height: 52,
    width: 52,
  },
  divBtn: {
    backgroundColor: "rgba(86, 49, 233, 0.74)",
    border: "none",
  },
  imgLike: {
    display: "block",
    margin: "auto",
  },
  buttonText: {
    fontSize: 20,
  },
  container: {
    marginTop: "auto",
  },
  containerMobile: {
    marginBottom: "auto",
  },
};
export default openFeedbackStyles;
