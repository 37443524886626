import { white } from "../constants/colors";

const wrapperStyle = {
    wrapper: {
        minHeight: "100%",
        margin: "auto",
        backgroundColor: white,
        borderRadius:"36px",
        boxShadow: "-16px -15px 36px #FFFFFF, 0px 14px 34px #D4DDED",
        display: "flex",
        flexFlow: "column",
        padding: "4vh 4%",
        wordWrap: 'break-word',
    },
}

export default wrapperStyle;