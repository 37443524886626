import React from "react";

const InputComponent = (props) => {
  return (
    <div style={props.flexRowDirectionStyle}>
      <input
        disabled={props.inputDisabled}
        ref={props.reff}
        value={props.inputValue}
        onChange={props.handleChangeEmail}
        onKeyDown={props.handleKeyDownEmail}
        placeholder={props.placeholder}
        style={props.inputStyle}
        onFocus={props.focus}
        onBlur={props.blur}
        maxLength={props.maxLength}
      ></input>
      <button
        onMouseEnter={props.onMouseEnter}
        onClick={props.OnClick}
        style={{
          ...props.buttonStyle,
          ...props.flexRowDirectionStyleForButton,
        }}
      >
        <div style={props.iconStyle}>
          <img src={props.Icon} alt={""}></img>
        </div>
        <div>{props.buttonTypeText}</div>
      </button>
    </div>
  );
};
export default InputComponent;
