import { white } from "../../constants/colors";

export const styles = {
  outerWrapper: {
    height: "100vh",
    width: "58%",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    margin: "auto",
    backgroundColor: white,
  },
  outerWrapperMobile: {
    width: "100%",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    backgroundColor: white,
  },
  outerWrapperSmall: {
    width: "85%",
  }
};
export default styles;