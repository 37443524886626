import { pillowTextBlue, errorRed } from "../constants/colors";
const styles = {
  inputStyle: {
    width: "100%",
    boxShadow:
      "inset 4px 0px 29px rgba(255, 255, 255, 0.6), inset 0px 4px 10px #ADB9CE",
    border: "none",
    borderRadius: 4,
    outlineColor: pillowTextBlue,
    paddingLeft: "1vw",
    paddingRight: "1vw",
    color: "black",
    fontSize: "1.125em",
    whiteSpace: "pre-line"
  },
  inputStyleMobile: {
    width: "100%",
    boxShadow:
      "inset 4px 0px 29px rgba(255, 255, 255, 0.6), inset 0px 4px 10px #ADB9CE",
    border: "none",
    borderRadius: 4,
    outlineColor: pillowTextBlue,
    paddingLeft: "5vw",
    paddingRight: "1vw",
    color: "black",
    fontSize: "1em",
    whiteSpace: "pre-line",

  },
  messageStyleError: {
    color: errorRed,
    fontSize: '0.875em',
  },
  errorMessage: {
    paddingLeft: "0.5vw",
  },
};

export default styles;
