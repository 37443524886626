import { white, grey, blackText } from "../../../../constants/colors";

const styles = {
    wrapper: {
      minHeight:"550px",
      height:"70vh",
      width:"100%",
      boxShadow: "-16px -15px 36px #FFFFFF, 0px 14px 34px #D4DDED",
      borderRadius: "36px",
      background: white,
      color: blackText,
    },
    smallResWrapper: {
      minHeight:"350px",
      height:"70vh",
      width:"90%",
      boxShadow: "-16px -15px 36px #FFFFFF, 0px 14px 34px #D4DDED",
      borderRadius: "36px",
      background: white,
      margin:"4vh auto 0",
      color: "black",
    },
    image: {
      borderTopLeftRadius:"36px",
      borderTopRightRadius:"36px",
      width: "100%",
      height: "41%",
      backgroundPosition:"center center",
      backgroundSize:"cover",
      backgroundRepeat:"no-repeat"
    },
    content: {
      display:"flex",
      flexDirection:"column",
      justifyContent:"space-around",
      height:"59%",
      padding:"0 1.2vw",
    },
    contentMobile: {
      display:"flex",
      flexDirection:"column",
      justifyContent:"space-around",
      margin:"0 3vw",
      height:"59%",
    },
    contentSmallRes: {
      margin:"0 1vw",
    },
    title: {
      fontSize: "2.28em",
    },
    mobileTitle: {
      fontSize: "1.6em",
      lineHeight:"1.5",
    },
    date: {
      color:grey,
      fontSize:"1em",
    },
    text: {
      fontSize:"1.25em",
    },
    mobileText: {
      fontSize:"1.2em",
    },
    greyText: {
      color:grey,
      fontSize:"1em",
    },
    
};
  
export default styles;
  