import { white, darkGrey } from '../../constants/colors';

const styles = {
  wrapperSmallerDesktopResolutions: {
    minHeight: "98%",
  },
  wrapperMobile: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-between",
    padding: "4% 7%",
  },
  categories: {
    marginTop: "1.5vh",
    marginLeft: "-1vw",
    marginBottom: "3%"
  },
  urlWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  //Field Heights Widths
  yourMessage: {
    height: "9.5vh",
    resize: "none",
    paddingTop: "4%",
  },
  yourMessageMobile: {
    height: "30vh",
    resize: "none",
    paddingTop: "8%",
  },
  email: {
    height: "5vh",
  },
  emailMobile: {
    height: "8vh",
  },
  fullName:{
    height: "5vh",
    marginBottom:"1.5vh"
  },
  fullNameMobile:{
    height: "8vh",
    marginBottom:"3vh"
  },
  url: {
    height: "5vh",
  },
  urlMobile: {
    height: "8vh",
    margin: "0% 2%",
  },
  btn: {
    margin: "2% 0 0% 0",
  },
  btnMobile: {
    marginTop: "10%",
  },
  loader: {
    position: "absolute",
    top: "-20%",
  },
  feedbackExchange: {
    margin: "0vh 0.75vw 0 0",
    color: darkGrey,
  },
  categoriesMobile: {
    width: "98%"
  },
};

export default styles;
