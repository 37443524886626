import React from "react";
import styles from "./Style_ActionButton";
import { mobileQuery, useMediaQuery, smallerDesktopResolutions } from "../../helpers/mediaQuery";
import Loader from "../loader/Loader";

/**
 * Action button component
 * @param children text to be displayed in the button
 * @param onPress, function to be executed when clicked
 * @param customStyle is a parameter that will be used to add additional style to this component
 * It will be primary used if the component needs some adjusting in another component or in a page view etc..
 * @param buttonType "submit" or "button" (submit submits form, but button dont) default is "button"
 * @param disabled bool, is the button disabled
 */

const ActionButton = ({
  onPress = () => null,
  customStyles,
  buttonType = "button",
  disabled,
  loaderEnabled,
  buttonText,
  loaderStyle,
}) => {
  
  let isMobile = useMediaQuery(mobileQuery);
  let isSmallerDesktopResolutions = smallerDesktopResolutions();
  
  return (
    <div style={{ display:"flex" }}>
      { loaderEnabled && <Loader customStyles={loaderStyle}/>}

      <input
        style={{
          ...styles.actionButton,
          ...styles.actionButtonText,
          ...(disabled
            ? styles.actionButtonDisabled
            : styles.actionButtonEnabled),
            ...(isMobile && { height: "34px", fontSize: "14px" }),
            ...(isSmallerDesktopResolutions ? { height: "32px" } : null),
            ...customStyles,
            
        }}
        type={buttonType}
        onClick={onPress}
        disabled={disabled}
        value={buttonText}
      >
      </input>
  </div>

  );
};

export default ActionButton;
