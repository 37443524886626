import React from "react";
import plusIcon from "../../../assets/icons/plus.svg";
import { helpQuestionBlue } from "../../../constants/colors";

const PlusButton = ({onPress = () => null, textlabel}) => {
  return (
    <button 
      style={plusButtonStyle} 
      onClick={onPress} 
      type="button"
    >
      <img src={plusIcon} alt="Add Button" style={{verticalAlign:"baseline"}}/>
      <span style={textStyle}>
          {textlabel}
      </span>
    </button>
  );
};

const plusButtonStyle =   {
  height: "min-content",
  border: "none",
  backgroundColor: "#F2F6FD",
  outline: "none",
}

const textStyle = {
  color: helpQuestionBlue,
  marginTop: "5px",
  lineHeight: 2,
  marginBottom: "1vh 0",
  marginLeft:"6px",
  letterSpacing:"0.04em",
  fontVariant:"all-small-caps",
  fontSize:"larger"
}

export default PlusButton;
