import React,{ useEffect } from "react";
import BlogParagraph from "../components/BlogParagraph";
import BlogHeading from "../components/BlogHeading";
import BlogImage from "../components/BlogImage";
import Title from "../components/Title"; 
import image1 from "../assets/images/article-1-1.jpg";
import image2 from "../assets/images/article-1-2.jpg";
import Date from "../components/Date";
import Writer from "../components/Writer";
import Dan from "../assets/images/Dan.png";
import firebase from "../../../helpers/firebaseAnalytics";
import MetaDecorator from "../../../helpers/MetaDecorator";

const ArticleOne = () => {
    useEffect(() => {
        firebase.analytics().logEvent(`Blog post Why feedback is important visited`);
      }, []);
return (
    <div>
        <MetaDecorator title={'Why feedback is important'} description={'The need for self-actualization is one of the primary driving forces of progress in human beings'}/>
        <Title>
            Why feedback is important
        </Title>
        <Writer
            writerImg={Dan} 
            writerName={"Dan Redzic"} 
            writerProfession={"Project manager"}
        />
        <Date>
            15 July, 2021
        </Date>
        <BlogParagraph>
            Thank you for the feedback is a phrase often heard these days throughout many offices big and small, and here’s why: The need for self-actualization is one of the primary driving forces of progress in human beings, and feedback is a simple, yet effective tool to achieve this. In order to facilitate said progress, we often have to look at the bigger picture; the system we find ourselves in and the role we play within, as well as ourselves, albeit from a different perspective, which is oftentimes difficult.
        </BlogParagraph>
        <BlogImage imageSrc={image1} />
        <BlogParagraph>
            To overcome this challenge, we turn to our peers and ask for evaluation, “the most cost effective performance tool available.”
        </BlogParagraph>
        <BlogHeading small>
            According to Tom Stafford, a scientist from the University of Sheffield, feedback is the essence of intelligence.
        </BlogHeading>
        <BlogHeading small>
            “Thanks to feedback we can become more than simple programs with simple reflexes, and develop more complex responses to the environment,” he writes. “Feedback allows animals like us to follow a purpose.” 
        </BlogHeading>
        <BlogParagraph>
            Naturally, most of us are very selective of whom we can ask feedback from (though we do not always have the luxury of such choice), the main reason being trust; who would you trust more to help you grow, friend or foe? If you do not believe a certain person to be a credible source of information, then the feedback that person delivers to you will also be regarded as such; invalid. In other words, just as it is important to give and receive feedback, it is also vital we do so with people we trust and respect. This is especially true in cases where certain people tend to mistake feedback with assault to their credibility and status.
        </BlogParagraph>
        <BlogImage imageSrc={image2} />
        <BlogParagraph>
            Research shows that a good majority (87%) of employees seek development in the workplace, but only a third of that actively seeks feedback. To me, personally, this gap makes sense for good reason; managers are often unsure of what kind of feedback employees want, and what kind they actually need to grow. In some cases, these overlap, but in some more dreaded, they do not.
        </BlogParagraph>
        <BlogHeading small>
            To overcome these fears and broken interactions, we decided to create a tool that:
        </BlogHeading>
        <BlogParagraph>
        • Makes it much easier to ask someone for feedback
        </BlogParagraph>
        <BlogParagraph>
        • Separates the content (of the feedback) from the person giving it, if necessary
        </BlogParagraph>
        <BlogParagraph>
        • Allows the permanence of received or given feedback
        </BlogParagraph>
        <br/>
        <BlogParagraph>
            Now that we have ascertained the importance of feedback, what remains to discuss are the practices of giving and receiving feedback (yes both good and bad). Until next time, stay strong!
        </BlogParagraph>
    </div>
    
);
};

export default ArticleOne;