export const validateURL = (value) => {
  let error;
  if (!value) {
    error = "Required";
  } else if (!/^[a-zA-Z0-9\-._~]+$/.test(value))
    error = "Invalid URL";
  else if (value.length > 20) error = "URL Too Long";
  else if (value.length < 1) error = "URL Too Short";
  return error;
};

export const validateEmail = (value) => {
  let error = null;
  if (!value) {
    console.log('here')
    error = "Required";
  } else if (
    !/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(
      value
    ) 
  ) 
    error = "Invalid email address";
  return error;
};
