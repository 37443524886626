// DEPENDENCIES
import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { useHistory } from "react-router-dom";
import firebase from "../../helpers/firebaseAnalytics";
// STYLES AND STYLE DEPENDENCIES
import styles from "./Style-WrapperFormik";
import wrapperStyle from "../../styles/Wrapper";
import StyleInput from "../../styles/InputField";
import "../../styles/PlaceholderAnimation.css";
// COMPONENTS
import MotionDiv from "../../components/motion-div/MotionDiv";
import CardHeaderText from "../../components/card-header-text/CardHeaderText";
import LabelFunctionality from "../../components/label-functionality-component/LabelFunctionality";
import ActionButton from "../../components/action-button/ActionButton";
import CategoriesButton from "../../components/categories-buttons/CategoriesButton";
import PlusButton from "./components/PlusButton";
import AddCategoryModal from "../../components/add-category-modal/AddCategoryModal";
// HELPERS
import api from "../../helpers/API";
import {
  getErrorStyle,
  ErrorMessage,
} from "../../helpers/field-error/FieldError";
import { validateEmail, validateURL } from "../../helpers/InputValidation";
import {
  mobileQuery,
  useMediaQuery,
  smallerDesktopResolutions,
} from "../../helpers/mediaQuery";
// VARIABLES
import { topOfThePageText } from "./constants/text";
import { defaultCategories, formikInitialValues } from "./constants/variables";


const FeedbackRequestWrapper = () => {
  // HELPERS
  const analytics = firebase.analytics();
  const isMobile = useMediaQuery(mobileQuery);
  const isSmallerDesktopResolutions = smallerDesktopResolutions();
  const history = useHistory();
  // STATES
  const [openAddCategory, setOpenAddCategory] = useState(false);
  const [urlExists, setUrlExists] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  // METHODS

  const loadingAndRedirectToSuccess = (url, userEmail, fullName) => {
    history.push({
      pathname: "/success",
      urlPath: window.location.href
        ? window.location.href
        : window.location.hostname,
      userUrl: url,
      userEmail: userEmail,
      fullName: fullName,
    });
  };
  
  const sendData = (values) => {
    setButtonDisabled(true);
    const data = {
      YourMessage: values.YourMessage,
      UserEmail: values.UserEmail,
      FullName: values.FullName,
      UserURL: values.UserURL.toLowerCase(),
      categoryName: values.categories
        .filter((category) => category.isPressed)
        .map((category) => category.value),
    };
    api
      .postFeedbackRequest(data)
      .then((res) => {
        console.log(res);
        loadingAndRedirectToSuccess(data.UserURL, data.UserEmail, data.FullName);
        setButtonDisabled(false);
      })
      .catch((error) => {
        console.log(error);
        setUrlExists(true);
        setButtonDisabled(false);
        firebase.analytics().logEvent("Error: postFeedbackRequest", {
          Component: "FeedbackRequestWrapper",
          error: error,
        });
      });
  };

  return (
    <Formik
      initialValues={formikInitialValues}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values) => sendData(values)}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        setFieldError,
        setFieldValue,
        touched,
        values,
        validateField,
      }) => {
        if (urlExists) errors.UserURL = "URL exists";
        return (
          <MotionDiv customStyles={{ height: "100%" }}>
            <Form
              style={{
                ...(isMobile ? styles.wrapperMobile : {...wrapperStyle.wrapper, ...{ justifyContent:"space-between" }}),
                ...(isSmallerDesktopResolutions && styles.wrapperSmallerDesktopResolutions),
              }}
              onSubmit={handleSubmit}
            >
              {/* HEADER TEXT */}
              <CardHeaderText>
                {topOfThePageText}
              </CardHeaderText>
              {/* ----------------------------
              OPEN MODAL FOR ADDING CATEGORY 
              ------------------------------*/}
              <LabelFunctionality
                textlabel={"CHOOSE A SKILL TO EVALUATE"}
                optionalFunctionality={
                  <PlusButton
                    textlabel={"ADD SKILL"}
                    onPress={() => {
                      setOpenAddCategory(!openAddCategory);
                      analytics.logEvent(
                        "FeedbackRequest user opened AddCategory"
                      );
                    }}
                  />
                }
                childStyle={styles.categories}
              >
                <div style={{ ...(isMobile && styles.categoriesMobile) }}>
                  {values.categories.map((category) => (
                    <Field
                      key={category.id}
                      value={category.value}
                      name={category.value}
                      as={CategoriesButton}
                      setIsPressed={(checked) => {
                        category.isPressed = checked;
                      }}
                      isChecked={category.id > 3 ? true : false}
                    />
                  ))}
                </div>
                <AddCategoryModal
                  categories={values.categories}
                  defaultLength={defaultCategories.length}
                  setCategories={setFieldValue}
                  setOpen={setOpenAddCategory}
                  isOpen={openAddCategory}
                />
              </LabelFunctionality>
              <LabelFunctionality
                textlabel={"ADDITIONAL DETAILS"}
                titleStyle={
                  isSmallerDesktopResolutions ? { lineHeight: 2 } : null
                }
              >
                <Field
                  className="animation"
                  style={{
                    ...(!isMobile
                      ? {
                          ...StyleInput.inputStyle,
                          ...styles.yourMessage,
                        }
                      : {
                          ...StyleInput.inputStyleMobile,
                          ...styles.yourMessageMobile,
                        }),
                    ...(isSmallerDesktopResolutions && {height: "6.5vh", paddingTop: "1%",}),
                  }}
                  name="YourMessage"
                  component="textarea"
                  placeholder="Describe what do you want feedback on (optional)"
                  autoComplete="off"
                />
              </LabelFunctionality>

              <LabelFunctionality
                textlabel={"ENTER YOUR EMAIL"}
                titleStyle={
                  isSmallerDesktopResolutions ? { lineHeight: 2 } : null
                }
              >
                <Field
                  className="animation"
                  style={{
                    ...getErrorStyle(errors.UserEmail),
                    ...(isMobile
                      ? {
                          ...StyleInput.inputStyleMobile,
                          ...styles.emailMobile,
                        }
                      : {
                          ...StyleInput.inputStyle,
                          ...styles.email,
                        }),
                  }}
                  name="UserEmail"
                  type="text"
                  placeholder="Your email"
                  validate={validateEmail}
                  onBlur={() => {
                    validateField("UserEmail");
                  }}
                />
                <ErrorMessage
                  message={errors.UserEmail}
                  touched={touched.UserEmail}
                />
              </LabelFunctionality>
              <LabelFunctionality
                textlabel={"FULL NAME"}
                titleStyle={
                  isSmallerDesktopResolutions ? { lineHeight: 2 } : null
                }
              >
                <Field
                  className="animation"
                  style={
                    !isMobile
                      ? {
                          ...StyleInput.inputStyle,
                          ...styles.fullName,
                        }
                      : {
                          ...StyleInput.inputStyleMobile,
                          ...styles.fullNameMobile,
                        }
                  }
                  name="FullName"
                  type="text"
                  placeholder="Your name (optional)"
                />
              </LabelFunctionality>
              <LabelFunctionality textlabel={"CREATE YOUR URL"}>
                <div style={styles.urlWrapper}>
                  <span style={styles.feedbackExchange}>
                    Feedback.Exchange/
                  </span>
                  <Field
                    className="animation"
                    name="UserURL"
                    type="text"
                    placeholder="Your URL"
                    onChange={(e) => {
                      if (urlExists) {
                        setFieldError("UserURL", "");
                        setUrlExists(false);
                        validateField("UserURL");
                      }
                      handleChange(e);
                    }}
                    style={{
                      ...getErrorStyle(errors.UserEmail),
                      ...(isMobile
                        ? {
                            ...StyleInput.inputStyleMobile,
                            ...styles.emailMobile,
                          }
                        : {
                            ...StyleInput.inputStyle,
                            ...styles.email,
                          }),
                    }}
                    validate={validateURL}
                    autoComplete="off"
                    onBlur={() => {
                      validateField("UserURL");
                    }}
                  />
                </div>
                <ErrorMessage
                  message={errors.UserURL}
                  touched={touched.UserURL}
                  customStyles={{ height: "19px" }}
                />
              </LabelFunctionality>
              <ActionButton
                customStyles={{ marginTop: "0.5rem" }}
                buttonText={ !buttonDisabled ? "CREATE MY FEEDBACK REQUEST" : "Processing" }
                buttonType="submit"
                disabled={buttonDisabled}
                loaderStyle={{ marginTop: "0.5rem" }}
                loaderEnabled={buttonDisabled}
              />
            </Form>
          </MotionDiv>
        );
      }}
    </Formik>
  );
};

export default FeedbackRequestWrapper;