
export const feedbackStyles = {
  feedBackRequest: {
    width: "75%",
  },
  feedBackRequestMobile: {
    marginTop: "10vh",
    width: "100%",
  }
};
export default feedbackStyles;
