import { helpQuestionBlue } from "../../constants/colors"
const style={
    modalContent:{
        minHeight:"285px",
        height:"45vh",
        overflowX: "hidden",
    },
    modalContentMobile:{
        height:"100vh",
        width:"100vw",
        left:"0",
        top:"0",
        marginTop:"56px",
        padding:"3vh 5vw",
        borderRadius:"none",
        justifyContent:"flex-start"
    },
    link:{
        margin:"0",
        fontSize:"1.125em",
        fontWeight: "bold",
        fontFamily:"Source Sans Pro",
        lineHeight:"1.375em",
        color:helpQuestionBlue,
    },
    button:{
        fontSize:"1em"
    },
    buttonMobile:{
        marginTop:"auto",
        fontSize:"3.5vmin",
        marginBottom:"20%",

    }
}
export default style