import React from "react";
import { blogParagraphBlack } from "../../../constants/colors";

const BlogHeading = ({ children, small }) => {
  return (
    <div style={small ? styles.headingSmall : styles.headingBig}>
      {children}
    </div>
  );
};

export default BlogHeading;

const styles = {
    headingBig: {
      fontSize:"2.5em",
      margin:"2vh 0",
      color: blogParagraphBlack,
      lineHeight:"1.5",
      fontFamily:"Playfair Display",
    },
    headingSmall:
    {
      fontSize:"1.5em",
      margin:"2vh 0",
      color: blogParagraphBlack,
      lineHeight:"1.5",
      fontFamily:"Playfair Display",
    },
}
