import React, { useRef, useState } from "react";
import CardHeaderText from "../card-header-text/CardHeaderText";
import styles from "./Style_FeedbackRequestSuccessfullyCreated";
import LabelFunctionality from "../label-functionality-component/LabelFunctionality";
import copyIcon from "../../assets/icons/copy.png";
import sendIcon from "../../assets/icons/send.png";
import IconSuccess from "../../assets/icons/iconSuccess.svg";
import SocialMediaComponent from "../social-media-component/SocialMediaComponent";
import InputComponent from "../input/InputComponent";
import { mobileQuery, useMediaQuery } from "../../helpers/mediaQuery";
import ActionButton from "../action-button/ActionButton";
import { NavLink, useLocation } from "react-router-dom";
import QRCode from "qrcode";
import downloadIcon from "../../assets/icons/downloadIcon.svg";
import api from "../../helpers/API";
import Tooltip from "../tooltip/Tooltip";
import MotionDiv from "../motion-div/MotionDiv";
import copy from 'copy-to-clipboard';
import firebase from "../../helpers/firebaseAnalytics";

const FeedbackRequestSuccessfullyCreated = (props) => {
  let isMobile = useMediaQuery(mobileQuery);

  const inputRef = useRef(null);
  const location = useLocation();
  const fullURL = location.userUrl ? "https://feedback.exchange/"+ location.userUrl : "";

  const [enteredEmail, setEnteredEmail] = useState('');
  

  // TODO: Implement dependcy injection here 
  const sendEmailsHandler = () => {
    const data = {
      "mailList": enteredEmail,
      "userUrl": location.userUrl,
      "userEmail": location.userEmail,
      "fullName": location.fullName,
    }

    setEnteredEmail('');
    
    api
    .sendToMultipleRecipients(data)
    .then((res) => {
      console.log(res);
    })
    .catch((error) => {
      alert(error);
      firebase.analytics().logEvent("Error: sendToMultipleRecipients", {
        Component: "FeedbackRequestSucessfullyCreated",
        error: error,
      });
    });
  }

  const copyToClipboard = () => {
    copy(fullURL);
  };
  
  const [imageUrl, setImageUrl] = useState("");
  const generateQr = async () => {
    const qr = await QRCode.toDataURL(
      fullURL ? fullURL : "https://feedback.exchange",
      { color: { light: "#0000" } }
    );
    setImageUrl(qr);
  };
  generateQr();

  return (
    <MotionDiv 
      customStyles={(isMobile ? styles.wrapperMobile : styles.wrapper)} 
    >
      <div style={
        isMobile ? 
        {...styles.wrapperText, ...styles.mobileWrapperText} : 
        styles.wrapperText }
      >
        <img src={IconSuccess} alt="Success" style={styles.iconSuccessStyle} />
        <CardHeaderText
          children={"Your feedback page is ready. Please check your spam foder."}
          customStyle={
            isMobile ? 
            {...styles.cardHeaderText, ...styles.cardHeaderMobile} : 
            styles.cardHeaderText } 
        />
      </div>
      <CardHeaderText
          customStyle={{marginTop:"4%"}}
          children={"Share the link and start receiving feedback"}
      />
      <LabelFunctionality
        customStyle={isMobile ? styles.labelStyleMobile : styles.labelStyle}
        titleStyle={{marginBottom:"3px"}}
        textlabel={"COPY YOUR LINK TO SHARE"}
      >
        <InputComponent
          flexRowDirectionStyle={styles.flexRowDirectionStyle}
          reff={inputRef}
          inputValue={fullURL}
          inputDisabled={true}
          inputStyle={styles.inputStyle}
          buttonStyle={
            !isMobile ? styles.buttonStyle : styles.buttonStyleMobile
          }
          handleChangeEmail={() => null}
          OnClick={copyToClipboard}
          flexRowDirectionStyleForButton={styles.flexRowDirectionStyle}
          iconStyle={styles.iconStyle}
          Icon={copyIcon}
          buttonTypeText={"COPY"}
        />
          <LabelFunctionality
          customStyle={isMobile ? styles.labelStyleMobile : styles.labelStyle}
          titleStyle={{marginBottom:"3px"}}
          childStyle={{width:"10%", alignSelf:"center"}}
          textlabel={"REQUEST FEEDBACK VIA EMAIL"}
        >
          <Tooltip
            children={"To send to multiple emails, use space or comma inbetween."}
            tooltipIconStyle = {styles.tooltipIcon}
          />
          </LabelFunctionality>
       {/* REQUEST FROM MULTIPLE EMAILS COMPONENT  vvvv*/}
       <InputComponent
          flexRowDirectionStyle={styles.flexRowDirectionStyle}
          reff={inputRef}
          inputDisabled={false}
          inputValue={enteredEmail}
          placeholder={"firstname@email.com, secondname@email.com, ..."}
          inputStyle={styles.inputStyle}
          buttonStyle={
            !isMobile ? styles.buttonStyle : styles.buttonStyleMobile
          }
          handleChangeEmail={(event) => setEnteredEmail(event.target.value)}
          OnClick={sendEmailsHandler}
          flexRowDirectionStyleForButton={styles.flexRowDirectionStyle}
          iconStyle={styles.iconStyle}
          Icon={sendIcon}
          buttonTypeText={"SEND"}
        />
      </LabelFunctionality>
      <div style={styles.socialMediaAndQrContainer}>
        <SocialMediaComponent
          userUrl={location.userUrl}
          customStyles={
            isMobile
              ? styles.flexRowDirectionStyleSocialMobile
              : styles.flexRowDirectionStyleSocial
          }
        />
        <div style={styles.qrWrapper}>
          <LabelFunctionality 
            textlabel={"DOWNLOAD QR"} 
            customStyle={{marginRight:"-40%"}}
          />
          <div style={styles.qrContainer}>
            <a href={imageUrl} download="Feedback QR">
              <img src={downloadIcon} alt="download qr"/>
            </a>
            <a href={imageUrl} download="Feedback QR Code">
              <img style={styles.qr} src={imageUrl} alt="Qr Code for download"/>
            </a>
          </div>
        </div>
      </div>
      <NavLink to={location.userUrl || "/"}>
        <ActionButton
          buttonType="button"
          buttonText={"Take Me To My Page"}
          onPress={() => {
            console.log("message");
          }}
          customStyles={
            isMobile ? styles.actionButtonStyleMobile : styles.actionButtonStyle
          }
        />
      </NavLink>
    </MotionDiv>
  );
};
export default FeedbackRequestSuccessfullyCreated;