import React, { Component } from "react";
import "./css/bootstrap.min.css";
import "./css/font-awesome.min.css";
import "./css/styles.css";
import "./css/loader.css";
import logo from "../../assets/icons/logo.svg";
import quote from "./images/quote_icon.svg";
import img1 from "./images/img_1.svg";
import img2 from "./images/img_2.svg" ;
import menuIcon from "./images/menuIcon.svg";
import cancel from "./images/cancel.svg"; 
import { NavLink } from "react-router-dom";
import Contact from "../../components/contact/Contact";
import Fade from 'react-reveal/Fade';
import Pulse from 'react-reveal/Pulse';
import Slide from 'react-reveal/Slide';
import firebase from "../../helpers/firebaseAnalytics";


export class LandingPage extends Component {
  constructor(props) {
  super(props);
  }
  
  componentDidMount() {
    firebase.analytics().logEvent("LandingPage visited");
  }

render() {
  const openNav = () => {
      document.getElementById("mobile-nav").style.display = "flex";
      document.body.style.overflowY="hidden";
  }
  const closeNav = () => {
      document.getElementById("mobile-nav").style.display = "none";
      document.body.style.overflowY="scroll";
  }
  return (
    <div id="body">
      <div>
        <div id="mobile-nav">
    			<img 
            src={cancel}
            alt={"Close Nav Menu"}
            onError="this.src='images/cancel.png'" 
            onClick={closeNav}
          />
          <button id="first-button" onClick={()=> firebase.analytics().logEvent("LandingPage clicked on Blog Button")}>
            <NavLink to="/blog" onClick={closeNav}>
              Blog
            </NavLink>
          </button>
    			<button id="second-button" onClick={()=> firebase.analytics().logEvent("LandingPage clicked on GiveUs Feedback Button")}>
            <NavLink to="/our-app" onClick={closeNav}>
              Give Us Feedback
            </NavLink>
          </button>
    			<button id="third-button" onClick={()=> firebase.analytics().logEvent("LandingPage clicked on Ask for Feedback Button")}>
            <NavLink to="/app" onClick={closeNav}>
              Ask for feedback
            </NavLink>
          </button>
    		</div>
    		<section id="header">
    			<div id="links">
    				<NavLink to="/" id="header-logo">
    					<img src={logo} />
    					<span className="white-text" id="logo-text">
                Feedback Exchange
              </span>
            </NavLink>    				
    				<span id="buttons">
    					<button 
                id="header-button-1" 
                className={["white-text", "links-margin"].join(' ')}
              >
                <NavLink to="/blog">
                  Blog
                </NavLink>
              </button>

    					<button 
                id="header-button-2" 
                className={["white-text", "links-margin"].join(' ')}
              >
                <NavLink to="/our-app">
                  Give Us Feedback
                </NavLink>
              </button>
    					<button id="header-button-3" className="white-text">
                <NavLink to="/app">
                  Ask for feedback
                </NavLink>
              </button>
    				</span>
    				<img alt=""  src={menuIcon} onerror="this.src='images/menuIcon.png'" id="menu-icon" onClick={openNav}/>
    			</div>
    			<div data-sr="ease-in" className={["container", " transparent-img"].join(' ')}>
    				<div className="row text-center">
    					<div id="fadein" className="col-md-8">
    						<img alt=""  className="logo" src={logo} />
    						<h1 className="white-text">Feedback Exchange</h1>
    						<h3 className="white-text" style={{"paddingBottom": "20px"}}>Where Feedback Flows</h3>
    						<NavLink to="/app">
                  <button 
                    data-sr="enter bottom over 1s, wait 0.3s, move 24px, reset" 
                    type="submit" 
                    className="button-leweb"
                    onClick={()=> firebase.analytics().logEvent("LandingPage clicked on Requesting Page Button")}
                  >
                  Request Feedback Today
                  </button>
                </NavLink>
    						<div id="countdown" className="white-text"></div>
    					</div>
    				</div>
    			</div>
    		</section>

        <div className="quote">
          <img alt=""  src={quote} style={{"width": "16px"}}/>
          <h3>Feedback.exchange is a  feedback collection app  with the potential to enter the everyday life   of   your   organization   and   everyone   who’s   part   of   it.     Whether   you’re   looking   for performance feedback, employee feedback, or client feedback, our simple platform allows you to reach out and ask for it.
          </h3>
          <h3>True exchanges carry value for everyone involved—when feedback is exchanged, cycles of mutual self-improvement spring forth and bear fruit.  Without feedback, it’s impossible to identify  your  strengths  or  hone  your skills but we  all   know  honest  critiques are  hard  to come by.
          </h3>
          <img alt=""  src={quote} style={{"width": "16px", "alignSelf": "flex-end"}}/>
        </div>

        <Fade left>
          <section style= {{"paddingBottom": "0px"}} id="about">
            <h2>Simple to Sign Up, Easy to Use, And Free</h2>
            <div className="container">
              <div className={["row", "text-center"].join(' ')}>
                <div data-sr="enter top over 1s, wait 0.2s, move 24px, reset" className="col-md-6">
                  <img 
                    src={img1} 
                    alt="Feedback Exchange App" 
                    width="100%" 
                    height="100%"
                  />
                </div>
                <div 
                  data-sr="enter bottom over 1s, wait 0.2s, move 24px, reset" 
                  className={["col-md-6", "text_collumn"].join(' ')}
                >
                  <h3>Asking for Feedback Has Never Been Easier</h3>						
                  <p>Simply   type   out   a   question   or  a  description   of   the  performance  feedback  interested   in, enter your email, and create a link.  After that, all you have to do is wait. </p>						
                </div>
              </div>
            </div>			
          </section>
        </Fade>
        <Fade right>
          <section id="about">
            <div className="container">
              <div className={["row", "reverse", "text-center"].join(' ')}>
                <div 
                  data-sr="enter bottom over 1s, wait 0.2s, move 24px, reset" 
                  className={["col-md-6", "text_collumn"].join(' ')}
                >
                  <h3>Circulate Your Link Far and Wide</h3>						
                  <p>When a mentor, colleague, or customer clicks on the link to your feedback request, they’ll be taken to our online form where they can let you know what they think.						
                  <br/><br/>And if you want really honest feedback, there’s an option for your respondees to submit their comments anonymously.</p>
                </div>
                <div data-sr="enter top over 1s, wait 0.2s, move 24px, reset" className="col-md-6">
                  <img 
                    src={img2}
                    alt="Feedback Exchange App immage" 
                    width="100%" 
                    height="100%"
                  />
                </div>
              </div>
            </div>			
          </section>
        </Fade>
        <Pulse>
          <section className="read_feedback">
            <div className="container">
              <div className={["row", "text-center"].join(' ')}>
                <div className={["row", "text-center", "col-md-6"].join(' ')}>
                  <div data-sr="enter bottom over 1s, wait 0.2s, move 24px, reset">
                    <div className="small">
                      <div className="tag_wrapper">
                        <div className="tag">Presentational Skills</div><div className="tag">
                          Communication
                        </div>
                      </div>
                      <img alt=""  src="./images/quote_icon.svg " style={{"width": "10px"}}/>
                      <p>
                        I can see that your written communication skills are getting better every day. That email you sent...
                      </p>
                      <img alt="" src="./images/quote_icon.svg" style={{"width": "10px", "alignSelf":"flex-end"}}/>
                      <p style={{"fontStyle": "italic", "paddingRight": "20px", "alignSelf": "flex-end"}}>
                        -Faris
                      </p>
                    </div>
                  </div>
                  <div data-sr="enter bottom over 1s, wait 0.2s, move 24px, reset">
                    <div className={["small", "second"].join(' ')}>
                      <div className="tag_wrapper">
                        <div className="tag">
                          Presentational Skills
                        </div>
                      </div>
                      <img alt=""  src="./images/quote_icon.svg " style={{"width": "10px"}}/>
                      <p>
                        You’re much more confident in meetings than when you started.  You explained that proposal...
                      </p>
                      <img alt=""  src="./images/quote_icon.svg" style={{"width": "10px", "alignSelf":"flex-end"}}/>
                      <p style={{"fontStyle": "italic", "paddingRight": "20px", "alignSelf": "flex-end"}}>
                        -Sarah
                      </p>
                    </div>
                  </div>
                  <div data-sr="enter bottom over 1s, wait 0.2s, move 24px, reset">
                  <div className="small third">
                    <div className="tag_wrapper">
                      <div className="tag">
                        Creativity
                      </div>
                      <div className="tag">
                        Communication
                      </div>
                    </div>
                    <img alt=""  src="./images/quote_icon.svg " style={{"width": "10px"}}/>
                    <p>
                      You’re adjusting to your new role really well, but there are still a few things to work on.  I think...
                    </p>
                    <img alt=""  src="./images/quote_icon.svg" style={{"width": "10px", "alignSelf":"flex-end"}}/>
                    <p style={{"fontStyle": "italic", "paddingRight": "20px", "alignSelf": "flex-end"}}>
                      -Anonymous
                    </p>
                  </div>
                </div>
              </div>
              <div 
                data-sr="enter bottom over 1s, wait 0.2s, move 0px, reset" 
                className={["col-md-6", "text_collumn", "no_padding"].join(' ')}
              >
                <h3>
                  Collect Feedback in One Place
                </h3>						
                <p>
                  We’ll   notify   you   by   email   once   people   start   responding   to   your  request   for   feedback. Everyone’s input will be displayed on a single overview page where that you can start your process of self-improvement.
                </p>						
              </div>
            </div>
          </div>			
        </section> 
      </Pulse> 
      <Slide top>
        <section id="subscribe">
          <div className="container">
            <div className={["row", "text-center"].join(' ')}>
              <div className="col-md-8 col-md-offset-2">
                <h2 data-sr="enter top over 1s, wait 0.3s, move 24px, reset" className="white-text">
                  We’re Just Getting Started
                </h2>
                <h5 data-sr="enter bottom over 1s, wait 0.3s, move 24px, reset" className="white-text">
                  Our email feedback request system is just the first step towards our goal of improving the communication infrastructure of organizations the world over.
                </h5>
              </div>
            </div>
          </div>	
        </section>
      </Slide>
      <div id="contactForm">
        <Fade bottom>
          <Contact 
            formText="Whether you’re a human resources manager with a need or an investor interested in helping us create an even more powerful feedback collection app, get in touch through the form below."
            buttonText="Send message"
            showMessage
            onClick={()=> firebase.analytics().logEvent("LandingPage clicked on SendMessage Button Form")}
          />
        </Fade>
      </div>
      
    		<footer id="footer">
    			<div className="container">
    				<div className="row text-center">	
    					<p>
    						&copy; 2021 | DESIGN BY <a href="https://neonada.org/#/">Neonada</a>
    						<span className="social">
    							<a href="https://www.facebook.com/feedback.exchageapp" ><i className="fa fa-facebook-square"></i></a>
    							<a href="https://www.instagram.com/feedback.exchange" ><i className="fa fa-instagram"></i></a> 
    							<a href="https://www.linkedin.com/company/neonada" ><i className="fa fa-linkedin-square"></i></a>
    						</span>
    					</p>
    				</div>
    			</div>		
    		</footer>
    	</div>
    </div>
  );
}
}

export default LandingPage;