import {darkGrey, whitePlain} from "../../constants/colors"

const styles = {
  title: {
    fontVariant: "all-small-caps",
    lineHeight: 1.25,
    color: darkGrey,
  },
  selectStyles: {
    option: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: isFocused ? "#EFEBFF" : whitePlain,
      color: "inherit",
    }),
    menu: (provided) => ({
      ...provided,
      minWidth: "240px",
      padding: "10px 0px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: "18px",
    }),
  },
};

export default styles;
