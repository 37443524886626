import React from 'react'
import LogErrorToFirestore from './LogErrorToFirestore'
import ErrorPage from '../error-page/ErrorPage'

class ErrorBoundary extends React.Component {
  
  state = {
    hasError: false,
  };

  static getDerivedStateFromError = error => {
    return { hasError: true };
  };

  componentDidCatch = (error, info) => {
    LogErrorToFirestore(error);
    this.setState({ error, info });
  };

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? <ErrorPage /> : children;
  }
}
export default ErrorBoundary