export const onMessageChangeHandle = (data, setData, event, currentCategoryIndex) => {
    console.log('event: ', event)
    let newArray = { ...data };
    let updatedValues = {
      isNew: data.feedbacks[0] && data.feedbacks[currentCategoryIndex].isNew,
      categoryName:
        data.feedbacks[0] && data.feedbacks[currentCategoryIndex].categoryName,
      feedbackMessage: event.target.value,
      signedBy:
        data.feedbacks[0] && data.feedbacks[currentCategoryIndex].signedBy,
    };
    newArray.feedbacks[currentCategoryIndex] = updatedValues;
    setData(newArray);
}

/**
 * 
 * @param {*} response 
 * @param {*} userUrl 
 * @param {*} setData 
 */
export const addValuesToData = (response, userUrl, setData) => {
  let formatedData = {
    userEmail: response.data.UserEmail,
    userFeedbackUrl: userUrl.toLowerCase(),
    signedBy: "anon",
    feedbacks: [],
  };
  response.data.categoriesArray &&
    response.data.categoriesArray.categoryName.map((element) => {
      formatedData.feedbacks.push({
        isNew: true,
        categoryName: element,
        feedbackMessage: "",
      });
    });
  setData(formatedData);
}