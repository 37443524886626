import React from "react";
import DesktopHeader from "../desktop-header/DesktopHeader";
import ActionButton from "../action-button/ActionButton";
import styles from "./StyleErrorPage";
import { mobileQuery, useMediaQuery } from "../../helpers/mediaQuery";
import MobileHeader from "../mobile-header/MobileHeader";

const Error = () => {
  let isMobile = useMediaQuery(mobileQuery);
  return (
    <div style={{ ...styles.div, ...(isMobile ? styles.divMobile : null) }}>
      {isMobile ? (
        <MobileHeader customStyle={styles.headerCustomMobile} />
      ) : (
        <DesktopHeader
          customStyle={styles.headerCustom}
          giveUsFeedbackStyle={styles.giveUsFeedback}
        />
      )}
      <div
        style={{
          ...styles.flexDiv,
          ...(isMobile ? styles.flexDivMobile : null),
        }}
      >
        <span
          style={{
            ...styles.error404,
            ...(isMobile ? styles.error404Mobile : null),
          }}
        >
          Ups
        </span>
        <span
          style={{
            ...styles.pageNotFound,
            ...(isMobile ? styles.pageNotFoundMobile : null),
          }}
        >
          Something went wrong 
        </span>
        <ActionButton
          customStyles={{
            ...styles.actionButton,
            ...(isMobile ? styles.actionButtonMobile : null),
          }}
          onPress={() => (window.location.href = "/report-bug")}
          buttonText="REPORT THE BUG"
        >
        </ActionButton>
      </div>
    </div>
  );
};

export default Error;
