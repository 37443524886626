import React from "react";
import ReadingFeedbackMiniHoverCard from "../reading-feedback-mini-hover-card/ReadingFeedbackMiniHoverCard";
import styles from "./Style_ReadingFeedbackCards";
import API from "../../helpers/API";

const ReadingFeedbackCards = (props) => {
   
  const { feedbacksUrl } = props;
  const feedbacksArray = [...props.feedbacksArray];

  const filter = (array) => {
    if (props.filter.length > 0) {
      const filteredArray = [];
      for (let filter of props.filter) {
        for (let feedback of array) {
          if (filter === feedback.userFeedbackUrl) filteredArray.push(feedback);
        }
      }
      return filteredArray;
    } else return array;
  };

  const sortByNew = (feedbacksArray) => {
    feedbacksArray.sort((x, y) => {
      if (x.isNew === y.isNew) {
        return 0;
      } else {
        if (x.isNew) {
          return -1;
        } else {
          return 1;
        }
      }
  });
  }
  
  sortByNew(feedbacksArray);

  return (
    <div style={styles.cardsWrapper}>
      {props.feedbacksArray && 
        filter(feedbacksArray).map((feedback, index) => (
          <ReadingFeedbackMiniHoverCard
            profileLink={props.profileLink}
            key={index}
            feedback={feedback}
            feedbacksUrl={feedbacksUrl}
            becomeOld={async () => {
              await API.updateFeedback(feedbacksUrl,feedback);
            }}
            feedbacksUrl={feedbacksUrl}
          />
        ))}
    </div>
  );
};
export default ReadingFeedbackCards;

