import { white, socialMediaHeaderTextBlue } from "../../constants/colors";

export const styles = { 
  wrapper:{
    overlay:{
      backgroundColor:white,
      zIndex: "4",
   },
    content: {
      height: "100vh",
      width:"100vw",
      border:"none",
      display:"flex",
      flexDirection:"column",
      backgroundColor:white,
      padding: '0%',
      left:'0%',
      inset:"0",
    },
  },
  links: {
    marginTop: "40%",
    height:"30%",
    fontSize:"2.8125vh",
    alignSelf:"center",
  },
  link: {
    marginBottom:"4vh",
    color: socialMediaHeaderTextBlue,
  },
  close: {
    position:"absolute",
    width:"5%",
    top:"3vh",
    right:"3vh",
  },
}

export default styles;









