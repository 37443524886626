import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import hamburger from '../../assets/icons/hamburger.svg';
import logo from '../../assets/icons/logo.svg';
import MobileMenuModal from '../mobile-nav/MobileMenuModal';
import styles from './Style-MobileHeader';

const appName = 'Feedback.Exchange';
const MobileHeader = ({customStyle}) => {
    const [modalVisible, setModalVisible]=useState(false)
    return (
        <div style={{...styles.MobileHeader, ...(customStyle && customStyle)}}>
            <NavLink to="/" style={styles.LinkToRoot}> 
                <img style={styles.LogoApp} src={logo} alt="Logo App" />
                <span style={styles.AppName}>
                    {appName}
                </span>                
            </NavLink> 
            <span>
                <img style={styles.hamburgerIcon} onClick={()=>setModalVisible(true)} src={hamburger} alt="Menu" />
                <MobileMenuModal 
                    setOpen={()=>setModalVisible(false)} 
                    visible={modalVisible}
                    setModalVisibleHandler={setModalVisible}
                    >
                </MobileMenuModal>  
            </span>  
                    
        </div>
    );
}
export default MobileHeader;