import React, { useEffect } from "react";
import styles from "./Style_ReadingFeedbackPage";
import "../../styles/hideAdSpace.css";
import Header from "../../components/header-component/Header";
import ReadingFeedbackWrapper from "../../pagesContentWrappers/reading-feedback-wrapper/ReadingFeedbackWrapper";
import firebase from "../../helpers/firebaseAnalytics";
import PageOuterWrapper from "../../components/page-outer-wrapper/PageOuterWrapper";
import { mobileQuery, useMediaQuery } from "../../helpers/mediaQuery";

const ReadingFeedbackPage = () => {
  const analytics = firebase.analytics();
  useEffect(() => {
    analytics.logEvent("ReadingFeedbackPage_visited");
  }, []);
  let isMobile = useMediaQuery(mobileQuery);
  return (
    <PageOuterWrapper>
      <div style={styles.adSpace} className="adSpaceHideOnMobile"></div>
      <div style={ isMobile ? styles.readingFeedbackMobile :  styles.readingFeedback }>
        <Header />
        <ReadingFeedbackWrapper />
      </div>
      <div style={styles.adSpace} className="adSpaceHideOnMobile"></div>
    </PageOuterWrapper>
  );
};
export default ReadingFeedbackPage;
