import React from "react";
import { grey } from "../../../constants/colors";
import { mobileQuery, useMediaQuery } from "../../../helpers/mediaQuery";

const Writer = ({writerImg, writerName, writerProfession}) => {
    return(
        <div style={styles.writer}>
            <img src={writerImg} style={styles.writerImg}/>
            <div style={styles.writerDetails}>
                <span>{writerName}</span>
                <span style={styles.greyText}>{writerProfession}</span>  
            </div>
        </div>
    );
};

export default Writer;

const styles = {
    writer: {
        display:"flex",
        fontSize:"1em",
        margin: "2vh 0",
    },
    writerImg: {
        width:"48px",
        height:"48px",
        borderRadius:"50%",
    },
    greyText: {
        color:grey,
        fontSize:"1em",
    },
    writerDetails: {
        display:"flex",
        flexDirection:"column",
        marginLeft:"1vw",
    }
}