import { pillowTextBlue, darkGrey } from "../../constants/colors"
const styles = {

  anonLabel: {
    position: "relative",
    right: "1.30vw",
    userSelect: "none",
    fontVariant: "all-small-caps",
    lineHeight: 1.25,
    color: darkGrey,
    fontSize: "1.25em",
    fontWeight: "400"
  },
  defaultCheckbox: {
    position: "relative",
    width: "1.1em",
    height: "1.1em",
    opacity: "0",
    userSelect: "none"
  },
  anonCheckbox: {
    position: "absolute",
    top: "0.3vh",
    marginLeft: "5px",
    width: "1.1em",
    height: "1.1em",
    borderRadius: "4px",
    backgroundColor: pillowTextBlue,
    lineHeight: "1.1em",
    textAlign: "center",
    color: "white",
    userSelect: "none"
  },
  anonUnCheckbox: {
    position: "absolute",
    top: "0.3vh",
    marginLeft: "5px",
    width: "1.1em",
    height: "1.1em",
    border: "1px solid darkGrey",
    borderRadius: "4px",
    lineHeight: "1.1em",
    textAlign: "center",
    color: "transparent",
    userSelect: "none"
  }
};

export default styles;
