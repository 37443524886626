// DEPENDENCIES
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
// STYLE AND STYLE DEPENDENICES
import styles from "./Style_GivingFeedbackWrapper";
import wrapperStyle from "../../styles/Wrapper";
import styleButton from "../../components/categories-buttons/style";
import "../../styles/PlaceholderAnimation.css";
import StyleInput from "../../styles/InputField";
// COMPONENTS
import MotionDiv from "../../components/motion-div/MotionDiv";
import CardHeaderText from "../../components/card-header-text/CardHeaderText";
import AnonCheckbox from "../../components/optional-components/AnonCheckbox";
import LabelFunctionality from "../../components/label-functionality-component/LabelFunctionality";
import ActionButton from "../../components/action-button/ActionButton";
import FeedbackRespond from "../../components/feedback-respond/FeedbackRespond";
import GivingFeedbackConfirmation from "../../components/giving-feedback-confirmation/GivingFeedbackConfirmation";
// HELPERS
import api from "../../helpers/API";
import {useMediaQuery,  mobileQuery, smallerDesktopResolutions} from "../../helpers/mediaQuery";
import firebase from "../../helpers/firebaseAnalytics";
import { onMessageChangeHandle,addValuesToData,} from "./helpers/Helpers_GivingFeedbackWrapper";
import {
  getErrorStyle,
  ErrorMessage,
} from "../../helpers/field-error/FieldError";

export default function GivingFeedbackWrapper() {
  const analytics = firebase.analytics();
  let isMobile = useMediaQuery(mobileQuery);
  const { userUrl } = useParams("");
  const feedbackExchange = "Feedback.Exchange/";

  // VALIDATION SCHEMA
  const givingFeedbackValidationSchema = Yup.object().shape({
    feedbackMessage: 
      Yup.string()
      .min(1,"Too short!"),
    userEmail: 
      Yup.string()
      .when([],{
        is: () => !anonymous,
        then: Yup.string().email("Invalid Email").required("required"),
        otherwise: Yup.string().notRequired(), 
      })
  })

  //styte for anonymous button
  let [anonymous, setAnonymous] = useState(false);
  //data returned from database contains UserEmail, YourMessage, categoriesArray,emailConfirmationKey, isEmailConfirmed
  const [serverResponse, setServerResponse] = useState([]);
  //contains array of categories with messages there is initial values set bacause of two way binding, and will be overwritten in addValuesToData
  const [data, setData] = useState({
    feedbacks: [{ isNew: true, categoryName: "", feedbackMessage: "" }],
  });
  //index of currently selected categoryresponse
  let [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

// for getting value of Formik Field 
  const [userEmailValue, setUserEmailValue] = useState("");
  const handleChange = event => {
        setUserEmailValue(event.target.value);
  };


  useEffect(() => {
    getDataFrom(userUrl);
  }, [userUrl]);

  const changeSignedBy = (email) => {
    data.signedBy = email;
  };
  const checkAnonymous = () => {
    setAnonymous(!anonymous);
  };
  //get data from server and push in serverResponse
  const getDataFrom = (userUrl) => {
    api.getFeedbackRequest(userUrl.toLowerCase()).then((response) => {
      setServerResponse(response.data);
      addValuesToData(response, userUrl, setData);
    }).catch((error) => {
      console.log(error);
      analytics.logEvent("Error: getFeedbackRequest", {
        Component: "GivingFeedbackWrapper",
        error: error,
      });
    });
  };
 
  return (
    <Formik
      validateOnBlur={false}
      enableReinitialize
      initialValues={{
        userUrl: "",
        userFeedbackUrl: "",
        userEmail: userEmailValue,
        signedBy: "",
        anon: false,
      }}
      validationSchema={givingFeedbackValidationSchema}
      onSubmit={() => {
        let feedbackData = [];
        for (let i = 0; i < data.feedbacks.length; i++) {
          feedbackData[i] = {
            userEmail: data.userEmail,
            userFeedbackUrl: userUrl.toLowerCase(),
            categoryName: data.feedbacks[i].categoryName,
            feedbackMessage: data.feedbacks[i].feedbackMessage,
            signedBy: anonymous ? "anonymous" : data.signedBy,
          };
        }
        setButtonDisabled(true);
        feedbackData.map((feedback) => {
          feedback.feedbackMessage !== "" && api.postFeedback(feedback);
        });
        setButtonDisabled(false);
        setConfirmationModal(true);
      }}
    >
      {(formikProps) => (
        <MotionDiv
          customStyles={{
            ...styles.motionDiv,
            ...(isMobile ? styles.motionDivMobile : null),
          }}
        >
          <Form
            style={
              isMobile ? styles.wrapperMobile  : { ...wrapperStyle.wrapper,  ...{height:"100%" }}
            }
            onSubmit={formikProps.handleSubmit}
          >
            {/* link  feedback.Exchange/userid ----------------------------------------*/}
            <div style={styles.UserURL}>
              <h5>
                {feedbackExchange}
                {userUrl.toLowerCase()}
              </h5>
            </div>
            {/* Category buttons--------------------------------------------- */}
            <div style={isMobile ? styles.categoriesMobile : styles.categories}>
              {data.feedbacks.map((dataEntry, index) => {
                return (
                  dataEntry.categoryName && (
                    <div
                      key={index}
                      style={
                        index === currentCategoryIndex
                          ? styleButton.button
                          :  {...styleButton.button, ...styleButton.pressedButton}
                      }
                      onClick={() => {
                        setCurrentCategoryIndex(index);
                      }}
                    >
                      {dataEntry.categoryName}
                    </div>
                  )
                );
              })}
            </div>
            <CardHeaderText
              customStyle={isMobile ? styles.yourMessageMobile : null}
            >
              {serverResponse.YourMessage}
            </CardHeaderText>
            <LabelFunctionality
              style={styles.feedback}
              textlabel={"WRITE YOUR FEEDBACK HERE"}
              optionalFunctionality={
                !isMobile ? (
                  <AnonCheckbox
                    click={() => {
                      checkAnonymous();
                      analytics.logEvent("Giving Feedback", {
                        check: "StayAnonymous Button",
                      });
                    }}
                  />
                ) : null
              }
              childStyle={styles.textBox}
            >
              <textarea
                id="inputGivingFeedback"
                style={isMobile ? styles.textAreaMobile : styles.textArea}
                className="TextAreaDiv animation"
                name="feedbackMessage"
                component="textarea"
                placeholder="What are your thoughts?"
                autoComplete="off"
                value={
                  data.feedbacks[0] &&
                  data.feedbacks[currentCategoryIndex].feedbackMessage
                }
                onChange={(event) =>
                  onMessageChangeHandle(
                    data,
                    setData,
                    event,
                    currentCategoryIndex
                  )
                }
              />
            </LabelFunctionality>
            <LabelFunctionality
              textlabel={"FULL NAME"}
              titleStyle={
                smallerDesktopResolutions ? { lineHeight: 2 } : null
              }
            >
              <Field
                disabled={anonymous}
                className="animation"
                style={{
                  // ...getErrorStyle(formikProps.errors.UserEmail),
                  ...(isMobile
                    ? {
                        ...StyleInput.inputStyleMobile,
                        height: "8vh",
                      }
                    : {
                        ...StyleInput.inputStyle,
                        height: "5vh",
                        width: "40%",
                      }),
                }}
                name="FullName"
                type="text"
                placeholder="Your name (optional)"
              />
            </LabelFunctionality>
            <LabelFunctionality
              textlabel={"YOUR EMAIL"}
              titleStyle={
                smallerDesktopResolutions && { lineHeight: 2 }
              }
            >
              <Field
                onBlur={() => {
                  formikProps.validateField("userEmail");
                }}
                disabled={anonymous}
                className="animation"
                style={{
                  ...(!anonymous && getErrorStyle(formikProps.errors.userEmail)),
                  ...(isMobile
                    ? {
                        ...StyleInput.inputStyleMobile,
                        height: "8vh",
                      }
                    : {
                        ...StyleInput.inputStyle,
                        height: "5vh",
                        width: "40%",
                      }),
                }}
                value={userEmailValue}
                onChange={handleChange}
                name="userEmail"
                type="text"
                placeholder="Your email"
              />
              <ErrorMessage
                message={formikProps.errors.userEmail}
                touched={formikProps.touched.userEmail}
              /> 
            </LabelFunctionality>
            {isMobile && (
              <span style={styles.anonMobile}>
                <AnonCheckbox
                  click={() => {
                    checkAnonymous();
                    analytics.logEvent("Giving anonymous StayAnonymous Button");
                  }}
                />
              </span>
            )}
            <ActionButton
              customStyles={!isMobile && {marginTop:"3%"}}
              buttonType={"submit"} 
              disabled={buttonDisabled}
              loaderEnabled={buttonDisabled}
              loaderStyle={isMobile ?  null: {marginTop:"3%"}}
              buttonText={buttonDisabled ? "PROCESSING" : "SEND" }
              onPress={() => {
                // formikProps.validateForm().then((t) => console.log('t:', t));
                changeSignedBy(userEmailValue);
                console.log("formikProps: ", formikProps)
                console.log("formikProps.isValid: ", formikProps.isValid)
                console.log("anonymous: ", anonymous)
                analytics.logEvent("Giving clicked Send Button");
              }}
            />
            <GivingFeedbackConfirmation
              isOpen={confirmationModal}
              setOpen={setConfirmationModal}
              userName={userUrl}
            />
            <FeedbackRespond
              confirmation={() => setConfirmationModal(true)}
              setOpen={setShowModal}
              submitForm={formikProps.handleSubmit}
              changeSignedBy={changeSignedBy}
              signedBy={data.signedBy}
              isOpen={showModal}
            />
          </Form>
        </MotionDiv>
      )}
    </Formik>
  );
}
