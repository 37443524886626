import { socialMediaHeaderTextBlue, white } from '../../constants/colors';

const styles = {
    MobileHeader: {
        display:"flex",
        boxSizing:"border-box",
        justifyContent:"space-between",
        alignItems:"center",
        boxShadow: "0px 4px 10px #D0D6DE, 0px 0px 10px 5px #FFFFFF",
        height:"56px",
        padding:"5%",
        width:"100vw",
        position:"fixed",
        top:"0%",
        left:"-0.1%",
        zIndex: "3",
        color:socialMediaHeaderTextBlue,
        backgroundColor:white,        
    },
    LinkToRoot: {
        width:"30%",
        display:"flex",
        justifyContent:"space-between",
        color:socialMediaHeaderTextBlue,
    },
    LogoApp: {
        verticalAlign: "middle",
        margin:"auto",
        height: "33.66943359375px",
        width: "35.42823791503906px",

    },
    AppName: {
        fontSize: '20px',
        textAlign:"center",
        verticalAlign: "middle",
        margin:"auto",
        paddingLeft:"5%",
    },
    hamburgerIcon: {
        width:"12.03px",
        height:"10px",
        margin:"auto",
    },
   
};
export default styles;