import { helpQuestionBlue, whitePlain } from "../../constants/colors";

const style = {
  wrapper: {
    maxWidth: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 34,
    background: helpQuestionBlue,
    color: whitePlain,
    padding: "1% 2%",
    marginRight: "0.5vw",
    marginBottom: "0.5vw",
  },
  text: {
    maxWidth:"95%",
    wordBreak: "break-all"
  },
  divIcon: {
    marginLeft: "0.5vw",
  },
  chipContent: {
    padding: "0 5px",
    overflowWrap: "break-word",
    wordBreak: "break-word",
    display: "flex",
  }
};
export default style;
