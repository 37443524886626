import firebase from 'firebase/app';

const devfirebaseConfig = {
    apiKey: "AIzaSyBj7JwtmWS3ZLuDZTp9iGFRzw7J2Jl287M",
    authDomain: "feedbackexchange-production.firebaseapp.com",
    databaseURL: "https://feedbackexchange-production-default-rtdb.firebaseio.com",
    projectId: "feedbackexchange-production",
    storageBucket: "feedbackexchange-production.appspot.com",
    messagingSenderId: "656250555942",
    appId: "1:656250555942:web:cb87a80c1e1eff62f7e0e7",
    measurementId: "G-K9GBJY9WKG"
  };

const firebaseInizialization = firebase.initializeApp(devfirebaseConfig);

export default firebaseInizialization;