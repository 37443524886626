import React from 'react';
import styles from './Style_LabelFunctionality';

/** 
 * LabelFunctionality component
 * @param textlabel text that will be displayed above the functionality component
 * @param children component where user can manipulate the data
 * @param optionalFunctionality? is a passed in element that can modify the state of the functionality component
*/
const LabelFunctionality = (props) => {
    return (
        <div style={{ ...styles.label, ...props.style,...props.customStyle }}>
            <span style={{  ...styles.title, ...props.titleStyle}} >
                {props.textlabel}
            </span>
            {props.optionalFunctionality && props.optionalFunctionality}
            <div style={{  ...styles.child, ...props.childStyle }}>{props.children}</div>
        </div>
    )
}

export default LabelFunctionality
