import React, { useState } from "react";
import quotationNew from "../../assets/icons/quotationNew.svg";
import { mobileQuery, useMediaQuery } from "../../helpers/mediaQuery";
import CardHeaderText from "../card-header-text/CardHeaderText";
import MobileHeader from "../mobile-header/MobileHeader";
import ModalPopupCard from "../modal-popup-card/ModalPopupCard";
import styles from "./Style_OpenFeedback";
import "./OpenFeedback.css";
import like from "../../assets/icons/like.png";
import dislike from "../../assets/icons/dislike.png";
import API from "../../helpers/API";

const OpenFeedback = (props) => {
  const { feedback, feedbacksUrl, isUseful } = props;
  const [checked, setChecked] = useState(feedback.isUseful);
  const isMobile = useMediaQuery(mobileQuery);
  const quotation = quotationNew;

  const isUsefulFunction = async (e) => {
    if (e.target.className === "no") {
      feedback.isUseful = false;
      setChecked(feedback.isUseful);
      await API.updateUsefulFeedback(feedbacksUrl, feedback);
      return;
    }
    if (e.target.className === "yes") {
      feedback.isUseful = true;
      setChecked(feedback.isUseful);
      await API.updateUsefulFeedback(feedbacksUrl, feedback);
      return;
    }
  };

  return (
    <ModalPopupCard
      customStyles={{
        ...styles.modal,
        ...(!isMobile && { overflowY: "hidden" }),
        ...props.mobileStyle,
      }}
      isModalVisible={props.isOpen}
      setOpen={props.setOpen}
    >
      {isMobile && (
        <div>
          <MobileHeader />
        </div>
      )}

      <CardHeaderText customStyles={styles.cardHeader} />
      {feedback.userFeedbackUrl && props.mobileOpened && (
        <div style={styles.linkDiv}>
          <p style={styles.linkText}>
            Feedback.Exchange/{feedback.userFeedbackUrl}
          </p>
        </div>
      )}
      {feedback.categoryName && (
        <div style={{...styles.category,...(isMobile && styles.categoryMobile)}}>{feedback.categoryName}</div>
      )}
      <div style={styles.feedbackFromStyle}>
        feedback from {feedback.signedBy}
      </div>
      <div style={styles.quotationStart}>
        <img
          style={styles.qutationStyle}
          src={quotation}
          alt="quotation-mark"
        />
      </div>
      <div style={styles.feedbackMessageStyle}>{feedback.feedbackMessage}</div>
      <div style={styles.quotationEnd}>
        <img
          style={styles.qutationStyle}
          src={quotation}
          alt="quotation-mark"
        />
      </div>
      {/* IS FEEDBACK USEFUL FUNCTIONALITY */}
      <div style={{...styles.container, ...(isMobile && styles.containerMobile) }}>
        <div style={styles.usefulDiv}>
          
          <span style={{...styles.usefulText, ...(isMobile && styles.usefulTextMobile) }}>
            Do you find this feedback useful?
          </span>
          {/* YES BUTTON  */}
          <div
            className={
              checked === undefined || checked === false 
                ? "button yes"
                : "button active yes"
            }
          >
            <button
              className="yes"
              onClick={isUsefulFunction}
              style={{...styles.buttonBlock, ...(isMobile && styles.buttonBlockMobile) }}
            >
              <img
                className="yes"
                style={styles.imgLike}
                src={like}
                alt="like"
              />
              <span className="yes" style={styles.buttonText}>
                Yes
              </span>
            </button>
          </div>
          {/* NO BUTTON */}
          <div
            className={
              checked === undefined || checked === true 
                ? "button no"
                : "button active no"
            }
          >
            <button
              className="no"
              onClick={isUsefulFunction}
              style={{...styles.buttonBlock, ...(isMobile && styles.buttonBlockMobile) }}
            >
              <img
                className="no"
                style={styles.imgLike}
                src={dislike}
                alt="dislike"
              />
              <span className="no" style={styles.buttonText}>
                No
              </span>
            </button>
          </div>
        </div>
      </div>
    </ModalPopupCard>
  );
};
export default OpenFeedback;
