import React, { useEffect } from "react";
import styles from "./Style_GivingFeedbackPage";
import "../../styles/hideAdSpace.css";
import GivingFeedbackWrapper from "../../pagesContentWrappers/giving-feedback-wrapper/GivingFeedbackWrapper";
import Header from "../../components/header-component/Header";
import { useMediaQuery, mobileQuery } from "../../helpers/mediaQuery";
import firebase from "../../helpers/firebaseAnalytics";
import PageOuterWrapper from "../../components/page-outer-wrapper/PageOuterWrapper";

/**
 * ClassName='adSpaceHideOnMobile' will be used for AdSpace component which will be shown on desktop only
 * Therefore, it will be hidden in the mobile app.
 */

const GivingFeedbackPage = () => {
  let isMobile = useMediaQuery(mobileQuery);

  useEffect(() => {
    firebase.analytics().logEvent("GivingFeedbackPage_visited");
  }, []);

  return (
    <PageOuterWrapper> 
      <div style={styles.adSpace} className="adSpaceHideOnMobile"></div>
      <div style={!isMobile ? styles.givingFeedback : styles.givingFeedbackMobile} >
        <Header />
        <GivingFeedbackWrapper />
      </div>
      <div style={styles.adSpace} className="adSpaceHideOnMobile"></div>
    </PageOuterWrapper>
  );
};
export default GivingFeedbackPage;
