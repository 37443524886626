import React from "react";
import styles from "./StyleCardHeaderText";
import { mobileQuery, useMediaQuery } from "../../helpers/mediaQuery";
import "../../styles/CardHeaderText.css";

/* *
 * CardHeaderText component
 * @param text to be displayed inside the CardHeaderText
 */
const CardHeaderText = ({ customStyle, children }) => {
  let isMobile = useMediaQuery(mobileQuery);

  return (
    <div className="cardHeaderText"
      style={{
        ...(isMobile ? { ...styles.cardTextMobile } : { ...styles.cardText }),
        ...(customStyle && customStyle),
      }}
    >
      {children}
    </div>
  );
};

export default CardHeaderText;
