import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import MotionDiv from "../../../components/motion-div/MotionDiv";
import BlogTileBig from "../components/blog-tile-big/BlogTileBig";
import BlogTileSmall from "../components/blog-tile-small/BlogTileSmall";
import tileOne from "../assets/images/article-1-1.jpg";
import tileTwo from "../assets/images/article-2-1.jpg";
import Dan from "../assets/images/Dan.png";
import Adna from "../assets/images/Adna.png";
import { Contact } from "../../../components/contact/Contact";
import styles from "./Style_BlogWrapper";
import { bigTileOne, bigTileTwo, otherPosts } from "../articles/blogPosts";
import { mobileQuery, smallRes, useMediaQuery } from "../../../helpers/mediaQuery";
import firebase from "../../../helpers/firebaseAnalytics";

const BlogWrapper = () => {
let isMobile = useMediaQuery(mobileQuery);
let isSmallRes = useMediaQuery(smallRes);
useEffect(() => {
    firebase.analytics().logEvent("Blog page visited");
  }, []);
return (
    <MotionDiv customStyles={isMobile ? styles.mobileWrapper : styles.wrapper}>
        <div style={
            isMobile ? 
            styles.mobileBigTiles : 
            isSmallRes ? {...styles.bigTiles, ...styles.smallResBigTiles} : 
            styles.bigTiles}
        >
            <NavLink 
                to="/blog/why-feedback-is-important" 
                style={isMobile || isSmallRes ? 
                    styles.linkBigMobile :
                    styles.linkBig
                }
            >
                <BlogTileBig 
                    blogPost={bigTileOne}
                    tileImage={tileOne}
                    writerImg={Dan}
                />
            </NavLink>
            
            <NavLink 
                to="/blog/the-art-of-feedback"
                style={isMobile || isSmallRes ? 
                    styles.linkBigMobile :
                    styles.linkBig
                }
            >
                <BlogTileBig 
                    blogPost={bigTileTwo}
                    tileImage={tileTwo}
                    writerImg={Adna}
                />
            </NavLink>
            
        </div>
        {/* {otherPosts.map((blogPost) => (
            <BlogTileSmall 
                blogPost={blogPost}
            />
        ))} */}
        <Contact 
            formText="Subscribe to our newsletter" 
            buttonText="Send"
            onClick={()=> firebase.analytics().logEvent("BlogPage clicked on Contact Send Button")}
        />
    </MotionDiv>
    );
};

export default BlogWrapper;