import React from "react";
import LabelFunctionality from "../label-functionality-component/LabelFunctionality";
import StyleSocialMediaComponent from "./StyleSocialMediaComponent";
import fb from "../../assets/icons/facebook.svg";
import linkedin from "../../assets/icons/linkedin.svg";
import { shareToFacebook, shareToLinkedIn } from "../../helpers/shareToSocialMedia";

const SocialMediaComponent = ({ customStyles, userUrl }) => {
  return (
    <LabelFunctionality
      textlabel={"SHARE"}
    >
      <div
        style={{
          ...StyleSocialMediaComponent.divIcons,
          ...customStyles,
        }}
      >
        <a onClick={()=>shareToFacebook(userUrl)}>
            <img  src={fb}/>
        </a>
        <a onClick={()=>shareToLinkedIn(userUrl)} style={{marginLeft:"2%"}}>
            <img src={linkedin}/>
        </a>

      </div>
    </LabelFunctionality>
  );
};

export default SocialMediaComponent;
