import React from "react";
import MobileModal from "./MobileModal.js";
import { NavLink } from "react-router-dom";
import styles from "./Style_MobileMenuModal.js";
import firebase from "../../helpers/firebaseAnalytics";
import "firebase/analytics";

const MobileMenuModal = (props) => {
  const analytics = firebase.analytics();
  return (
    <div>
      <MobileModal
        customStyles={styles.wrapper}
        isModalVisible={props.visible}
        setOpen={props.setOpen}
        setModalVisibleHandler={props.setModalVisible}
      >
        <div style={styles.links}>
          <NavLink
            to="/app"
            onClick={() => { props.setOpen(false); }}
          >
            <div style={styles.link}>
              Create Your Feedback Page
            </div>
          </NavLink>
          <NavLink
            to="/our-app"
            onClick={() => { 
              props.setOpen(false);
              analytics.logEvent("Mobile Header giveUs clicked");
            }}
          >
            <div style={styles.link}>
              Give Us Feedback
            </div>
          </NavLink>
          <NavLink
            to="/blog"
            onClick={() => { props.setOpen(false); }}
          >
            <div style={styles.link}>
              Blog
            </div>
          </NavLink>
          <NavLink
            to="/"
            onClick={() => { props.setOpen(false); }}
          >
            <div style={styles.link}>
              Home
            </div>
          </NavLink>
        </div>
      </MobileModal>
    </div>
  );
};
export default MobileMenuModal;
