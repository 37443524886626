import backgroundImageSvg from "../../assets/images/ErrorPageBackground.svg";
import backgroundImagePng from "../../assets/images/ErrorPageBackground.png";
import backgroundImageMobileSvg from "../../assets/images/ErrorPageBackgroundMobile.svg";
import backgroundImageMobilePng from "../../assets/images/ErrorPageBackgroundMobile.png";

const styles = {
  div: {
    width: "100vw",
    height: "100vh",
    backgroundImage: `url(${backgroundImagePng})`,
    backgroundImage: `url(${backgroundImageSvg})`,
    backgroundSize: "cover",
    overflowX: "hidden",
    overflowY: "hidden",
  },
  divMobile: {
    backgroundImage: `url(${backgroundImageMobilePng})`,
    backgroundImage: `url(${backgroundImageMobileSvg})`,
  },
  headerCustom: {
    paddingLeft: "40%",
    paddingRight: "15%",
  },
  headerCustomMobile: {
    background: "transparent",
    boxShadow: "none",
  },
  flexDiv: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "56.8vw",
    marginTop: "18.27vh",
    width: "fit-content",
    alignItems: "center",
  },
  flexDivMobile: {
    marginLeft: "25.5vw",
    marginTop: "25.3vh",
  },
  error404: {
    fontSize: "11em",
    lineHeight: "19.5vh",
  },
  error404Mobile: {
    fontSize: "5.25em",
    lineHeight: "105px",
  },
  pageNotFound: {
    fontFamily: "Playfair Display",
    fontSize: "2.5em",
    lineHeight: "70px",
    display: "block",
  },
  pageNotFoundMobile: {
    fontSize: "1.125em",
    lineHeight: "24px",
  },
  actionButton: {
    width: "10.6vw",
    height: "4.3vh",
    marginTop: "10%",
    horizontalPadding: "20px",
  },
  actionButtonMobile: {
    width: "49vw",
    lineHeight: "1.125em",
  },
  giveUsFeedback: {
    background: "#F2F6FD",
  },
};

export default styles;
