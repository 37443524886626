import React from 'react';
import "./Loader.css";

const Loader = ({customStyles}) => {
    
    return (
        <div style= {customStyles} className="lds-ring">
            <div></div>
        </div>
    );
  };
  export default Loader;
  