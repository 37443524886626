import { white } from "../../constants/colors";
export const givingFeedbackStyles = {
  outerWrapper: {
    minHeight: "100vh",
    display: "flex",
    flexFlow: "row",
    justifyContent: "center",
    backgroundColor: "#F2F6FD",
    paddingRight: '21%',
    paddingLeft: '21%',
  },
  outerWrapperMobile: {
    minHeight: "100vh",
    display: "flex",
    flexFlow: "row",
    justifyContent: "center",
    backgroundColor: "#F2F6FD",
  },
  readingFeedback: {
    width: "75%",
    backgroundColor: white,
  },
  readingFeedbackSmall: {
    width: "85%",
  },
  readingFeedbackMobile: {
    width: "90%",
    backgroundColor: white,
  },
  adSpace: {
    width: "12.5%",
  },
};
export default givingFeedbackStyles;
