import { grey, socialMediaHeaderTextBlue } from '../../../constants/colors';

const styles = {
    wrapper:{
        marginBottom:"2%", 
        minHeight:"96%",
    },
    wrapperMobile: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexFlow: "column",
        padding: "4% 7%",
        marginTop:"70px",
        marginBottom:"10%",
    },
    back: {
      fontSize:"1.25em",
      color: socialMediaHeaderTextBlue,
      fontFamily:"Poppins",
      letterSpacing: "0.63px",
      display:"flex",
      alignItems:"center",
      marginBottom: "4vh",
    },
    backImg: {
        marginRight: "10px",
    },
    title: {
        fontSize: "2.28em",
        margin: "2vh 0 0",
    },
    date: {
        color:grey,
        fontSize:"1em",
        margin: "2vh 0",
    },
};
  
export default styles;