// DEPENDENCIES
import React from "react";
import { NavLink } from "react-router-dom";
// STYLES AND STYLE DEPENDENCIES
import styleHeader from "./Style_DesktopHeader.js";
import logo from "../../assets/icons/logo.svg";
//HELPERS
import firebase from "../../helpers/firebaseAnalytics";

const appName = "Feedback.Exchange";

const DesktopHeader = () => {
  const analytics = firebase.analytics();
  
  return (
    <div
      style={styleHeader.headerStyle}
    >
      <NavLink to="/" style={styleHeader.linkToRoot}>
        <img style={styleHeader.logoApp} src={logo} alt="Logo App" />
        <span style={styleHeader.appName}>{appName}</span>
      </NavLink>
      
      <div>
        <NavLink
          style={{...styleHeader.links, marginRight:"10px"}}
          onClick={() => {analytics.logEvent("Blog clicked");}}
          to="/blog"
        >
          <span>Blog</span>
        </NavLink>
        <NavLink
          style={{...styleHeader.links, marginRight:"10px"}}
          onClick={() => {analytics.logEvent("Create Your Feedback Page clicked");}}
          to="/app"
        >
          <span>Create Your Page</span>
        </NavLink> 
        <NavLink
          style={styleHeader.links}
          onClick={() => {analytics.logEvent("Give Us Feedback Page clicked");}}
          to="/our-app"
        >
          <span>Give Us Feedback</span>
        </NavLink>
        
      </div>
    </div>
  );
};
export default DesktopHeader;

