/**
 * id: 0, value: "Technical Skills", isPressed: false 
 * id: 1, value: "Performance", isPressed: false 
 * id: 2, value: "Communication", isPressed: false
 * id: 3, value: "Presentation Skills", isPressed: false
 */
 export const defaultCategories = [
    { id: 0, value: "Technical Skills", isPressed: false },
    { id: 1, value: "Performance", isPressed: false },
    { id: 2, value: "Communication", isPressed: false },
    { id: 3, value: "Presentation Skills", isPressed: false },
  ];
  
  /**
   *     
   * YourMessage: "",
   * UserEmail: "",
   * FullName: "",
   * UserURL: "", 
   * categories: defaultCategories,
   */
  export const formikInitialValues = {
    YourMessage: "",
    UserEmail: "",
    FullName: "",
    UserURL: "",
    categories: defaultCategories,
  }