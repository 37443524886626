import React, { useRef, useState, useEffect } from "react";
import ModalPopupCard from "../modal-popup-card/ModalPopupCard";
import CardHeaderText from "../card-header-text/CardHeaderText";
import LabelFunctionality from "../label-functionality-component/LabelFunctionality";
import addIcon from "../../assets/icons/plus.png";
import styles from "./Style_AddCategoryModal";
import InputComponent from "../input/InputComponent";
import Chip from "../chip/Chip";
import ActionButton from "../action-button/ActionButton";
import Hr from "../hr/hr";
import lineModal from "../../assets/icons/modalLine.png";
import { mobileQuery, useMediaQuery } from "../../helpers/mediaQuery";
import firebase from "../../helpers/firebaseAnalytics";

const AddCategoryModal = (props) => {
  const analytics = firebase.analytics();
  let isMobile = useMediaQuery(mobileQuery);

  const inputRef = useRef(null);
  const [categories, setCategories] = useState(props.categories);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [newID, setNewID] = useState(categories[categories.length - 1].id + 1);
  const [isFocused, setIsFocused] = useState(false);

  const addCategory = () => {
    if (inputRef.current && inputRef.current.value) {
      setCategories([
        ...categories,
        {
          id: newID,
          value: inputRef.current.value,
          isPressed: true,
        },
      ]);
      inputRef.current.value = null;
      setButtonDisabled(false);
      setNewID(newID + 1);
    }
  };

  const removeCategory = (id) => {
    const index = categories.findIndex((element) => element.id === id);
    let copyArray = categories.slice();
    copyArray.splice(index, 1);
    setCategories(copyArray);
    setButtonDisabled(false);
  };

  // Pressing Enter will add the category
  useEffect(() => {
    const handleEsc = (event) => {
      event.keyCode === 13 && addCategory();
    };
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);
  const toggleModal = (e)=>{
    e.persist();
    let line = document.getElementsByClassName("ReactModal__Content ReactModal__Content--after-open")[0];
    line.style.transform= "translate(0, 100%)";
    line.style.transition = "transform 0.5s";
    setTimeout(()=> props.setOpen(false),500);
  }

  return (
    <ModalPopupCard
      isModalVisible={props.isOpen}
      setOpen={props.setOpen}
      customStyles={
        !isMobile
          ? styles.modalWrapper
          : isFocused
          ? styles.modalWrapperMobileFocused
          : styles.modalWrapperMobile
      }
    >
      <img
        src={lineModal}
        alt={"Line"}
        style={isMobile ? styles.modalLineStyleMobile : styles.modalLineStyle}
        onTouchMove={toggleModal}
        className="line"      
      />
      <div 
      style={styles.cardHeaderDiv}
      onTouchMove={toggleModal}
      >
        <CardHeaderText customStyle={styles.cardHeader}>
          Can't find a skill? Add a new one.
        </CardHeaderText>
      </div>
      <div>
        <LabelFunctionality
          customStyle={styles.labelTextStyle}
          textlabel={"enter new skills"}
          titleStyle={{margin: "10px 0"}}
        >
          <InputComponent
            flexRowDirectionStyle={styles.flexRowDirectionStyle}
            reff={inputRef}
            placeholder={"Creativity, Independence, etc..."}
            inputStyle={!isMobile ? styles.inputStyle : styles.inputStyleMobile}
            buttonStyle={
              !isMobile ? styles.buttonStyle : styles.buttonStyleMobile
            }
            OnClick={() => {
              addCategory();
              analytics.logEvent("FeedbackRequestPage: New Category Attempt", {
                customCategories: categories,
              });
            }}
            flexRowDirectionStyleForButton={styles.flexRowDirectionStyle}
            iconStyle={styles.iconStyle}
            Icon={addIcon}
            buttonTypeText={"ADD"}
            focus={isMobile && (() => setIsFocused(true))}
            blur={isMobile && (() => setIsFocused(false))}
            onMouseEnter={isMobile && (() => addCategory())}
            maxLength={"83"}
          />
        </LabelFunctionality>
      </div>
      <div style={styles.chipsDiv}>
        <div style={styles.chips}>
          {categories.slice(props.defaultLength).map((category) => (
            <Chip
              key={category.id}
              displayText={category.value}
              index={category.id}
              removeChip={() => removeCategory(category.id)}
              customStyles={styles.chip}
              darkIcon
            />
          ))}
        </div>
      </div>
      <div style={styles.actionDiv}>
        <Hr />
        <ActionButton
          buttonType="button"
          buttonText="CONFIRM"
          customStyles={
            !isMobile
              ? styles.actionButtonStyle
              : styles.actionButtonStyleMobile
          }
          disabled={buttonDisabled}
          onPress={() => {
            props.setCategories("categories", categories);
            props.setOpen(false);
            analytics.logEvent("FeedbackRequestPage: New Category Added", {
              customCategories: categories,
            });
          }}
        />
      </div>
    </ModalPopupCard>
  );
};
export default AddCategoryModal;