import React, { useState } from "react";
import styleOptions from "./Style_OptionalFunctionality";
import { Field } from "formik";

const AnonCheckbox = ({click}) => {
  const [checked, setChecked] = useState(false);
  let btn_class = checked
    ? styleOptions.anonCheckbox
    : styleOptions.anonUnCheckbox;
  return (
    <label style={styleOptions.anonLabel}>
      {"STAY ANONYMOUS"}
      <span style={btn_class}>
        ✓
        <Field
          style={styleOptions.defaultCheckbox}
          type="checkbox"
          name="anon"
          onClick={() => {
            setChecked(!checked);
            click()
          }}
        />
      </span>
    </label>
  );
};

export default AnonCheckbox;
