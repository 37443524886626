import copy from 'copy-to-clipboard';
const params = "menubar=no,toolbar=no,status=no,width=570,height=570"; 

export const shareToFacebook = (url) => {
    let shareUrl = `https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ffeedback.exchange%2F${url}`;
    window.open(shareUrl, "NewWindow", params);  
    
}
export const shareToTwitter = (url) => {
    let shareUrl = `https://twitter.com/intent/tweet?url=https%3A%2F%2Ffeedback.exchange%2F${url}&text=`;
    window.open(shareUrl,"NewWindow" , params);
}
export const shareToLinkedIn = (url) => {
    let shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Ffeedback.exchange%2F${url}&title=`;
    window.open(shareUrl, "NewWindow", params);
}
export const copyToClipboard = () => {
    copy(window.location.href);
  };