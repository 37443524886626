import React from "react";
import "./styles/GlobalStyles.css";
import "./styles/App.css";
import GlobalStyle from "./styles/GlobalStyleComponent";
import { Route, Switch } from "react-router-dom";
import RequestingFeedbackPage from "./pages/requesting-feedback/RequestingFeedbackPage";
import ReadingFeedbackPage from "./pages/reading-feedback/ReadingFeedbackPage";
import GivingFeedbackPage from "./pages/giving-feedback/GivingFeedbackPage";
import AboutUsPage from "./pages/about-us/AboutUsPage";
import FeedbackRequestSuccessfullyCreatedPage from "./pages/feedback-request-successfully-created-page/FeedbackRequestSuccessfullyCreatedPage";
import ErrorBoundary from "./components/error-boundary/ErrorBoundary"
import { AnimatePresence } from 'framer-motion';
import ErrorPage from "./components/error-page/ErrorPage";
import LandingPage from "./pages/landing-page/LandingPage";
import Blog from "./pages/blog/Blog";
import BlogSingle from "./pages/blog/BlogSingle";

function App() {
  return (
    <ErrorBoundary>
        <AnimatePresence>
          <Switch>
            <Route exact path="/app" exact component={RequestingFeedbackPage} />
            <Route path="/aboutUs" exact component={AboutUsPage} />
            <Route exact path="/blog" exact component={Blog} />
            <Route exact path="/error" component={ErrorPage} />  
            <Route exact path="/success" component={FeedbackRequestSuccessfullyCreatedPage} />
            <Route exact path="/:userUrl" component={GivingFeedbackPage} />
            <Route path="/read/:feedbacksUrl" component={ReadingFeedbackPage} />
            <Route path="/blog/:article" component={BlogSingle} /> 
            <Route path="/" component={LandingPage} />

          </Switch>
        </AnimatePresence>
      <GlobalStyle />
    </ErrorBoundary>
  );
}

export default App;