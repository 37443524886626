import React from 'react';
import DesktopHeader from "../../components/desktop-header/DesktopHeader";
import MobileHeader from "../../components/mobile-header/MobileHeader";
import { mobileQuery, useMediaQuery  } from "../../helpers/mediaQuery";

const Header = () => {
    
  let isMobile = useMediaQuery(mobileQuery);
  return (
      <>
          { isMobile ? <MobileHeader/> : <DesktopHeader/> } 
      </>           
  );
}
export default Header;