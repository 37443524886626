import iconError from "../../assets/icons/info.png";

/**
 * get's the styles if an error exists (&& check)
 * 
 * @param {*} errors  
 * @returns backgroundColor: "#FFEDED",color: "#2F1E1E"
 */
export const getErrorStyle = (errors) => errors && {backgroundColor: "#FFEDED",color: "#2F1E1E"}


export const ErrorMessage = ({ message, touched, customStyles,  messageStyleError }) =>
{
  const errorMessage = message && touched ? 
  (
    <div >
      <img src={iconError && iconError} alt="" />
      <span >{message}</span>
    </div>
  ) : (
    <div style={customStyles ? customStyles : { height: "21px" }  }></div>
  );

  return errorMessage;
}