// DEPENDENCIES 
import React, { useEffect } from "react";
// STYLE AND STYLE DEPENDENCIES
import FeedbackStyles from "./Style_RequestingFeedbackPage";
import "../../styles/hideAdSpace.css";
// COMPONENTS 
import FeedbackRequestWrapper from "../../pagesContentWrappers/feedback-request-wrapper/FeedbackRequestWrapper";
import Header from "../../components/header-component/Header";
// HELPERS
import { mobileQuery, useMediaQuery } from "../../helpers/mediaQuery";
import firebase from "../../helpers/firebaseAnalytics";
import PageOuterWrapper from "../../components/page-outer-wrapper/PageOuterWrapper";

/**
 * ClassName='adSpaceHideOnMobile' will be used for AdSpace component which will be shown on desktop only
 * Therefore, it will be hidden in the mobile app.
 */
const RequestingFeedbackPage = () => {
  useEffect(() => {
    firebase.analytics().logEvent("RequestingFeedbackPage_visited");
  }, []);

  const isMobile = useMediaQuery(mobileQuery);

  return (
    <PageOuterWrapper>
      <div
        style={
          !isMobile
          ? FeedbackStyles.feedBackRequest
          : FeedbackStyles.feedBackRequestMobile
        }
      >
        <Header />
        <FeedbackRequestWrapper />
      </div>
    </PageOuterWrapper>
  );
};
export default RequestingFeedbackPage;
