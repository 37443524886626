/**
for: pillow text 
 */
export const pillowTextBlue = "#4F35F2";
/**
for: labels for input fields 
 */
export const grey = "#636E82";
/**
 * for: feedback from on open feedback component
 */
export const feedbackFormGrey="#687285";
/**
for: categories button pressed
 */
export const whiteCategoriesButtonPressed = "#FFFDFD";
export const pressedCategoryText = "#525254";
/**
 for: action button text
*/
export const whiteActionButtonText = "#F2F2F3";
// for: disabled action button
export const disabledButton="#747F94";
/**
for: hovercard background, categories button default , feedback request
 */
export const white = "#F2F6FD";
/**
for: chip text
 */
export const whitePlain="#FFFFFF";
/**
for: hovercard title
 */
export const black = "#2F1E1E";
/**
for: help questions example: Need help? or Need a feedback? 
 */
export const helpQuestionBlue = "#5631E9";
/**
for: blog paragraphs
 */
export const blogParagraphBlack = "#2B2B29";
/**
 * for: black text
 */
export const blackText = "#000000";
/**
for: social media  
 */
export const socialMediaHeaderTextBlue = "#5631E9";
/**
for: feedback page link, respondents email link 
 */
export const lightBlue = "#587AE8";
/**
 * color of placeholder
 */
export const lightGrey = "#B4BECF"
/**
 * color of textLabel 
 */
export const darkGrey = "#636E82";
/**
 * color of <Hr> line 
 */
export const lineGrey = "#D5DBE4";
/**
 * color of error message
 */
export const errorRed = "#CA4338";