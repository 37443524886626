import React from "react";
import styles from "./Style_PageOuterWrapper";
import { mobileQuery, smallDesktop, useMediaQuery } from "../../helpers/mediaQuery";

const PageOuterWrapper = ( {children} ) => {
    let isMobile = useMediaQuery(mobileQuery);
    let isSmallDesktop = useMediaQuery(smallDesktop);
    return (
        <div style={ 
            isMobile ? styles.outerWrapperMobile : 
            {...styles.outerWrapper, ...isSmallDesktop && styles.outerWrapperSmall }}
        >
            {children}
        </div>
      );
    };

export default PageOuterWrapper;
