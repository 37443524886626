import React, { useState, useRef } from "react";
import OpenFeedback from "../open-feedback/OpenFeedback";
import styles from "./Style_ReadingFeedbackMiniHoverCard";
import quotationOld from "../../assets/icons/quotationOld.svg";
import quotationNew from "../../assets/icons/quotationNew.svg";
import quotationOldMobile from "../../assets/icons/quotationOldMobile.svg";
import quotationNewMobile from "../../assets/icons/quotationNewMobile.svg";
import newTagIcon from "../../assets/icons/newTag.svg";
import { mobileQuery, useMediaQuery } from "../../helpers/mediaQuery";
import firebase from "../../helpers/firebaseAnalytics";

/**
 *
 * @param feedbackText represents feedback text
 * @param isNew represents part of feedback objet that tells us if the feedback was read by user
 */

const ReadingFeedbackMiniHoverCard = ({
  feedback,
  becomeOld,
  feedbacksUrl,
}) => {
  const analytics = firebase.analytics();
  const [openModal, setOpenModal] = useState(false);
  let isMobile = useMediaQuery(mobileQuery);
  const quotationImgOld = isMobile ? quotationOldMobile : quotationOld;
  const quotationImgNew = isMobile ? quotationNewMobile : quotationNew;
  const quotation = feedback.isNew ? quotationImgNew : quotationImgOld;
  let mobile = isMobile ? styles.modalWrapperMobile : null;
  const quotationSize = "width:{20px}, height:{20px}";

  return (
    <>
      {
        // Check if feedbackMessage is a string or empty
        typeof feedback.feedbackMessage === "string" &&
          feedback.feedbackMessage !== "" && (
            <div
              style={styles.miniCardWrapper}
              onClick={() => {
                setOpenModal(!openModal);
                feedback.isNew = false;
                becomeOld();
                analytics.logEvent("Reading Feedback Open feedback");
              }}
            >
              <div style={styles.topContainer}>
                <div style={styles.quotationStart}>
                  <img
                    src={quotation}
                    alt={"quotes"}
                    style={{
                      width: { quotationSize },
                      height: { quotationSize },
                    }}
                  />
                </div>
                {feedback.isNew && (
                  <img style={styles.newWrap} alt="new" src={newTagIcon} />
                )}
              </div>
              <div style={styles.miniCardText}>
                {feedback.feedbackMessage.substring(0, 100) +
                  (feedback.feedbackMessage.length > 100 ? "..." : "")}
              </div>
              <div style={styles.quotationEnd}>
                <img
                  src={quotation}
                  alt={"quotes"}
                  style={{
                    width: { quotationSize },
                    height: { quotationSize },
                  }}
                />
              </div>
            </div>
          )
      }
      <OpenFeedback
        mobileOpened={isMobile}
        mobileStyle={mobile}
        feedback={feedback}
        isOpen={openModal}
        setOpen={setOpenModal}
        feedbacksUrl={feedbacksUrl}
        isUseful={feedback.isUseful}
      />
    </>
  );
};
export default ReadingFeedbackMiniHoverCard;
