import React, { useEffect } from "react";
import AboutUsWrapper from "../../pagesContentWrappers/about-us-wrapper/AboutUsWrapper";
import aboutUsStyles from "./Style_AboutUsPage.js";
import { mobileQuery, useMediaQuery } from "../../helpers/mediaQuery";
import Header from "../../components/header-component/Header";
import firebase from "../../helpers/firebaseAnalytics";

const AboutUsPage = () => {
  let isMobile = useMediaQuery(mobileQuery);
  useEffect(() => {
    firebase.analytics().logEvent("AboutUsPage_visited");
  }, []);
  return (
    <div style={aboutUsStyles.outerWrapper}>
      <div
        style={isMobile ? aboutUsStyles.aboutUsMobile : aboutUsStyles.aboutUs}
      >
        <Header />
        <AboutUsWrapper />
      </div>
    </div>
  );
};
export default AboutUsPage;
