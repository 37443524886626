import { white } from "../../constants/colors";

export const modalPopupCardStyles = {
    wrapper:
    {
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '39.5%',
            height: "71.2%",
            background: white,
            margin: 'auto',
            position: 'absolute',
            boxShadow: '0% 4% 24% rgba(0, 0, 0, 0.15)',
            borderRadius: 18,
            outline: 'none',
            padding: '2.5%',
        }
    },
    close: {
        alignSelf: 'flex-end',
    }
}
export default modalPopupCardStyles;