import React from "react";
import Header from "../../components/header-component/Header";
import PageOuterWrapper from "../../components/page-outer-wrapper/PageOuterWrapper";
import BlogWrapper from "../../pagesContentWrappers/blog-wrapper/blog-total/BlogWrapper";
import styles from "./Style_Blog";
import { mobileQuery, useMediaQuery } from "../../helpers/mediaQuery";
import MetaDecorator from "../../helpers/MetaDecorator";

const Blog = () => {
  let isMobile = useMediaQuery(mobileQuery);
    return (
      <PageOuterWrapper>
        <MetaDecorator title={'Feedback.Exchange Blog!'} description={'Learn and grow with Feedbacks'}/>
          <div style={isMobile? styles.blogMobile : styles.blog}>
            <Header/>
            <BlogWrapper/>  
          </div>
      </PageOuterWrapper>
    );
  };
  export default Blog;
