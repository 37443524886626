import { helpQuestionBlue, whitePlain } from '../../constants/colors';

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    width: "38vw",
    margin: "auto"
  },
  smallDesktopWrapper: {
    width: "50vw",
  },
  title: {
    fontFamily: "Source Sans Pro",
    marginBottom: "1rem",
    fontStyle: "normal"
  },
  titleMobile: {
    fontFamily: "Source Sans Pro",
    marginBottom: "1rem",
    marginTop: "1rem",
    fontStyle: "normal"
  },
  filters: {
    display: "flex",
    flexFlow: "row wrap",
    minHeight: "5.5vh",
    marginBottom: "2vh",
  },
  chipStyle: {
    color: helpQuestionBlue,
    background: whitePlain,
    boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "34px",
    padding: "1% 2%",
  },
  chipStyleMobile: {
    marginRight: "2vw",
    marginBottom: "1vh",
  },
};

export default styles;
