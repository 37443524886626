import { whiteActionButtonText, disabledButton } from "../../constants/colors";

export const actionButtonStyle = {
  actionButton: {
    width: "100%",
    height: "38px",
    borderRadius: 34,
    outline: "none",
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  actionButtonEnabled: {
    background:
      "linear-gradient(177.19deg, #8470FE 2.03%, #6764FF 20.93%, #6048F3 42.31%, #4E33F1 78.65%, #3F30ED 94.46%)",
    boxShadow: "-2px -7px 6px #FFFFFF, 2px 4px 9px rgba(42, 72, 172, 0.93)",
  },
  actionButtonDisabled: {
    background: disabledButton,
    boxShadow: "2px 4px 9px #ACACAC",
  },
  actionButtonText: {
    color: whiteActionButtonText,
    fontStyle: "normal",
    fontWeight: "bold",
    letterSpacing: "0.15em",
    textTransform: "uppercase",
    fontSize: "1.8vh",
  },

};
export default actionButtonStyle;