import { pillowTextBlue, white, whiteCategoriesButtonPressed, pressedCategoryText } from '../../constants/colors';

const styles = {
  button:{
    justifyContent: "center",
    alignItems: "center",
    padding:"0.5vmin 1.5vmin",
    minWidth:"100px",
    maxWidth: "100%",
    marginLeft:"1vw",
    marginBottom:"1.2vh",
    overflowWrap:"break-word",
    color: pressedCategoryText,
    background: whiteCategoriesButtonPressed,
    borderRadius: 34,
    boxShadow: "inset 1px 0px 1px rgba(200, 198, 198), inset 0px 4px 5px #ADB9CE",
    flexGrow: 0,
    outline: "none",
    border: "none",
    cursor:"pointer"
  },
  buttonMobile:{
    minHeight:"35%",
    left: "0%",
    right: "0%",
    top: "0%",
    bottom: "0%",
  },
  pressedButton: {
    color: pillowTextBlue,
    background: white,
    boxShadow: "-10px -10px 20px #FFFDFD, 0px 18px 34px #ADB9CE",
  },
};
export default styles;
