import { socialMediaHeaderTextBlue } from '../../constants/colors';
const styleHeader = {
    headerStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'transparent',
        width: '100%',
        height: '5vh',
        margin: '2% 0% 2% 0%',
        color: socialMediaHeaderTextBlue,
        zIndex: '2',
        fontSize: "1em",
    },
    linkToRoot: {
        display: 'flex',
    },
    logoApp: {
        width: 'auto',
        verticalAlign: 'middle',
        marginRight: '10px',
    },
    appName: {
        fontWeight: 'bold',
        alignSelf: 'center',
        color: socialMediaHeaderTextBlue,
    },
    links: {
        border: '1px solid #4F35F2',
        boxSizing: 'border-box',
        borderRadius: '34px',
        height: '1.7em',
        padding: '0 8px',
        textAlign: 'center',
        justifyContent: 'center',
        color: socialMediaHeaderTextBlue,
    }
};
export default styleHeader;