import React, { useEffect } from "react";
import FeedbackStyles from "./Style_FeedbackRequestSuccessfullyCreatedPage";
import "../../styles/hideAdSpace.css";
import Header from "../../components/header-component/Header";
import { mobileQuery, useMediaQuery } from "../../helpers/mediaQuery";
import FeedbackRequestSuccessfullyCreated from "../../components/feedback-request-created-modal/FeedbackRequestSuccessfullyCreated";
import firebase from "../../helpers/firebaseAnalytics";
import PageOuterWrapper from "../../components/page-outer-wrapper/PageOuterWrapper";

/**
 * ClassName='adSpaceHideOnMobile' will be used for AdSpace component which will be shown on desktop only
 * Therefore, it will be hidden in the mobile app.
 */
const FeedbackRequestSuccessfullyCreatedPage = () => {
  let isMobile = useMediaQuery(mobileQuery);

  useEffect(() => {
    firebase
      .analytics()
      .logEvent("FeedbackRequestSuccessfullyCreatedPage_visited");
  }, []);

  return (
    <PageOuterWrapper>
      <div
        style={
          isMobile
            ? FeedbackStyles.feedBackRequestMobile
            : FeedbackStyles.feedBackRequest
        }
      >
        <Header />
        <FeedbackRequestSuccessfullyCreated />
      </div>
      <div
        style={FeedbackStyles.feedBackRequestAdspace}
        className="adSpaceHideOnMobile"
      ></div>
    </PageOuterWrapper>
  );
};
export default FeedbackRequestSuccessfullyCreatedPage;
