import React from "react";

const BlogImage = ({ imageSrc }) => {
  return (
    <img style={styles.image} src={imageSrc}/>
  );
};

export default BlogImage;

const styles = {
    image: {
      width:"100%",
      height:"auto",
      margin:"2vh 0",
    },
}