import { motion } from "framer-motion";
import { pageVariants, pageTransition } from "../../styles/PageAnimation";

const MotionDiv = ({children, customStyles}) => {

    return (
        <motion.div
            style={customStyles}
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
        >
            {children}
        </motion.div> 
    )
}

export default MotionDiv;