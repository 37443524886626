import { socialMediaHeaderTextBlue } from "../../constants/colors";


const styles = {
  modal: {
    overflow:"hidden",
    height: "80%",
  },
  modalMobile:{
    height:"90vh",
    width:"100%",
    padding:"5%",
    marginTop:"10vh",
    left:"0",
    top:"0",
    border:"none",
    borderRadius:"none"
  },
  cardHeaderTextStyle: {
    marginTop: "5%",
  },
  wrapper: {
    height: "100%",
    width: "100%",
    border: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  wrapperMobile:{
    height: "100%",
    width: "100%",
    border: "none",
    display: "flex",
    flexDirection: "column",
    position:"relative"
  },
  inputStyle: {
    width: "100%",
    height: "80%",
    marginTop: "3%",
    padding: "1.8% 1vw",
    boxShadow:
      "inset 4px 0px 29px rgba(255, 255, 255, 0.6), inset 0px 4px 10px #ADB9CE",
    border: "none",
    borderRadius: 4,
  },
  inputStyleMobile:{
    width: "100%",
    marginTop: "3%",
    padding: "4%",
    boxShadow:
      "inset 4px 0px 29px rgba(255, 255, 255, 0.6), inset 0px 4px 10px #ADB9CE",
    border: "none",
    borderRadius: 4,
  },
  actionButton: {
    width: "100%",
    marginTop: "3%",
  },
  actionButtonMobile: {
    width: "100%",
    marginTop:"auto"
  },
  links: {
    color: socialMediaHeaderTextBlue,
    display: "flex",
    justifyContent: "space-between",
  },
  linksMobile:{
    display:"none"
  },
  logoPic: {
    height: "33.66943359375px", 
    width: "35.42823791503906px",
  }
};
export default styles;