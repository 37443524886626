import React from "react";
import { grey } from "../../../constants/colors";
import { mobileQuery, useMediaQuery } from "../../../helpers/mediaQuery";


const Date = ({ children }) => {
let isMobile = useMediaQuery(mobileQuery);
    return (
    <div style={isMobile ? styles.mobileDate : styles.date}>
      {children}
    </div>
  );
};

export default Date;

const styles = {
    date: {
        color:grey, 
        fontSize:"1em", 
        margin: "2vh 0"
    },
    mobileDate: {
        color:grey, 
        fontSize:"1em", 
        margin: "1vh 0 4vh"
    },
}
