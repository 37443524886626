
export const feedbackStyles = {
  feedBackRequest: {
    width: "75%",
    height: "88%",
  },
  feedBackRequestMobile: {
    marginTop: "56px",
    width: "100%",
  },
  adSpace: {
    width: "25%",
  },
};
export default feedbackStyles;
