// DEPENDENICES
import React from "react";
import { NavLink } from "react-router-dom";
// STYLES AND STYLE DEPENDENCIES
import styles from "./Style_AboutUsWrapper";
// COMPONENTS
import MotionDiv from "../../components/motion-div/MotionDiv";
import CardHeaderText from "../../components/card-header-text/CardHeaderText";
import ActionButton from "../../components/action-button/ActionButton";
// HELPERS
import { mobileQuery, useMediaQuery } from "../../helpers/mediaQuery";

const AboutUsWrapper = () => {
  let isMobile = useMediaQuery(mobileQuery);
  return (
    <MotionDiv
      customStyles={isMobile ? styles.mobileWrapper : styles.wrapper}
    >
      <CardHeaderText>
        This is About Us page and here is a placeholder for some cool title
      </CardHeaderText>
      <div style={isMobile ? styles.mobileText : styles.text}>
        <p>
          Here is some text about Rate Me app, what is the main inspiration for
          making it, whay is it important to ask for the feedback, what are the
          values of a feedback.
        </p>
        <p>
          Here is some text about Rate Me app, what is the main inspiration for
          making it, whay is it important to ask for the feedback, what are the
          values of a feedback.
        </p>
        <p>
          Here is some text about Rate Me app, what is the main inspiration for
          making it, whay is it important to ask for the feedback, what are the
          values of a feedback.
        </p>
        <p>
          Here is some text about Rate Me app, what is the main inspiration for
          making it, whay is it important to ask for the feedback, what are the
          values of a feedback.
        </p>
      </div>
      <NavLink to="/">
        <ActionButton
          customStyles={
            isMobile ? styles.mobileActionButton : styles.ActionButton
          }
          children={"REQUEST FEEDBACK PAGE"}
          type="submit"
          onPress={() => {
            console.log("Message");
          }}
        />
      </NavLink>
      <div style={isMobile ? styles.adSpace : styles.mobileAdSpace}></div>
    </MotionDiv>
  );
};

export default AboutUsWrapper;
