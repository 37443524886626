const styles = {
    wrapper: {
        height:"100%",
    },
    mobileWrapper: {
        marginTop:"56px",
        marginBottom:"10%",
        padding: "0 5%",
    },
    linkBig: {
        width:"48%",
    },
    linkBigMobile: {
        width:"100%",
    },
    bigTiles: {
        width: "100%",
        display:"flex",
        flexDirection:"row",
        justifyContent:"space-between",
    },
    smallResBigTiles: {
        flexDirection:"column",
    },
    mobileBigTiles: {
        width: "100%",
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-between",
    },
  };
  
export default styles;