import axios from "axios";

let axiosConfig = {
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  },
};
/**
 * we have created baseUrl for our database which is static part that does not change
 * then for each method we have created dynamic parts of the url
 * these dynamic parts can be adjusted accordingly
 *
 * @getFeedback(id) is a get method used to fetch a certain feedback using id as a parameter
 * @getFeedbackRequest(id) is a get method used to fetch a certain feedback request using id as a parameter
 * @postFeedback(feedbackObject) posting object to database using connection string
 * @postFeedbackRequest(feedbackRequestObject) posting object to database using connection string
 */
var devEnv= `https://europe-west3-ratemedev-3c2dc.cloudfunctions.net`;
var prodEnv = `https://europe-west3-feedbackexchange-production.cloudfunctions.net`;
var localEnv = `http://localhost:5001/feedbackexchange-production/europe-west3`;
var baseUrl = prodEnv; 

export class APIHelper {
  instance;
  constructor() {
    this.instance = axios.create({
      // baseUrl: `https://europe-west3-feedbackexchange-production.cloudfunctions.net`,
    });
  }
  static getFeedback = (feedbacksUrl) => {
    return axios.get(
      `${baseUrl}/FeedbackGet/FeedbackGet?feedbacksUrl=${feedbacksUrl}`
    );
  };

  static getFeedbackRequest = (userUrl) => {
    const getFeedbackRequestUrl = `/FeedbackRequestGet`;
    return axios.get(`${baseUrl}${getFeedbackRequestUrl}?UserURL=${userUrl}`);
  };

  static postFeedback = (postFeedbackObject) => {
    axios
      .post(`${baseUrl}/FeedbackPost`, postFeedbackObject, axiosConfig)
      .then((res) => {
        console.log(res);
      });
  };

  static postFeedbackRequest = (postFeedbackRequestObject) => {
    const postFeedbackRequestUrl = `/FeedbackRequestPost`;
    return axios.post(
      `${baseUrl}${postFeedbackRequestUrl}`,
      postFeedbackRequestObject,
      axiosConfig
    );
  };
  static postError = (postErrorObject) => {
    const postError = `/errorLogging`;
    return axios.post(`${baseUrl}${postError}`, postErrorObject, axiosConfig);
  };

  static updateFeedback = (feedbacksUrl, updateFeedbackObject) => {
    return axios.post(
      `${baseUrl}/FeedbackUpdate/FeedbackUpdate?feedbacksUrl=${feedbacksUrl}`,
      updateFeedbackObject,
      axiosConfig
    );
  };
  static sendToMultipleRecipients= (sendToMultipleRecipientsObject) => {
    const sendToMultipleRecipients = `/SendToMultipleRecipients`;
    return axios
      .post(
        `${baseUrl}${sendToMultipleRecipients}`,
        sendToMultipleRecipientsObject,
        axiosConfig
      )
  };

  static updateUsefulFeedback = (feedbacksUrl, updateFeedbackObject) => {
    return axios.post(
      `https://us-central1-feedbackexchange-production.cloudfunctions.net/FeedbackUpdateUseful?feedbacksUrl=${feedbacksUrl}`,
      updateFeedbackObject,
      axiosConfig
    );
  };
}
export default APIHelper;
