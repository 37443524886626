import { black } from '../../constants/colors';

const miniHoverCardStyles = {
  miniCardWrapper: {
    width: "100%",
    height:"fit-content",
    borderRadius: 18,
    boxShadow: "-4px -4px 12px 3px #FFFFFF, 4px 5px 28px -1px #C7D2E4",
    marginBottom: "2.5vh",
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-between"
  },
  modalWrapperMobile:{
    height:'100%',
    width:'100%',
    justifyContent:"flex-start",
    left:0,
    padding:'8%',
    marginTop:"0",
    borderRadius:0
   },
  miniCardText: {
    fontFamily: "Source Sans Pro",
    color: black,
    overflowWrap:"break-word",
    padding: "0% 10%",
    fontWeight: "normal",
    fontSize: 14,
    wordWrap: "break-word",
  },
  topContainer: {
    height: "37.5%",
    display: "flex",
    justifyContent: "space-between",
  },
  newWrap: {
    height: 50,
    width: 50,
  },
  quotationStart: {
    marginTop: "2%",
    marginLeft: "5%",
  },
  quotationEnd: {
    height: "37.5%",
    marginBottom: "2%",
    alignSelf: "flex-end",
    marginRight: "5%",
  },
};
export default miniHoverCardStyles;
