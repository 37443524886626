const style = {
  cardText: {
    fontFamily: "Playfair Display",
    whiteSpace: "pre-line",
    lineHeight: 1.35,
  },
  cardTextMobile: {
    fontFamily: "Playfair Display",
    whiteSpace: "pre-line",
    lineHeight: 1.35,
  },
};

export default style;
