import React from "react";
import { blogParagraphBlack } from "../../../constants/colors";

const BlogParagraph = ({ children }) => {
  return (
    <div style={styles.text}>
      {children}
    </div>
  );
};

export default BlogParagraph;

const styles = {
    text: {
      fontSize:"1.25em",
      margin:"1vh 0",
      color: blogParagraphBlack,
      lineHeight:"1.5",
    },
}
