import { white } from '../../constants/colors';

export const aboutUsStyles = {
  outerWrapper: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    backgroundColor: white,
    overflow:"hidden",
  },
  aboutUs: {
    width: "75%",
  },
  aboutUsMobile: {
    marginTop: "56px",
    width: "100%",
    height:"70vh",
  },
  adSpace: {
    width: "25%",
  },
};
export default aboutUsStyles;