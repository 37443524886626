import React, { useState } from "react";
import styles from "./style";
 import { mobileLandspaceQuery, useMediaQuery  } from "../../helpers/mediaQuery";

const CategoriesButton = ({setIsPressed, value, isChecked}) => {
  
  let isMobileLandspace = useMediaQuery(mobileLandspaceQuery);
  const [checked, setChecked] = useState(isChecked);
  
  let btn_class = { 
    ...styles.button, 
    ...checked ? null : styles.pressedButton
  }

  let btn_class_Mobile = { 
    ...styles.button, ...styles.buttonMobile, 
    ...checked ? null : styles.pressedButton
  }

  return (
    <button
      type="button"
      style={isMobileLandspace ? btn_class : btn_class_Mobile}
      onClick={() => {
        setChecked(!checked);
        setIsPressed(!checked);
      }}
    >
      {value}
    </button>
  );
};

export default CategoriesButton;
