import React from "react";
import ReactTooltip from 'react-tooltip';
import info from "../../assets/icons/tooltip.svg";
import { feedbackFormGrey } from "../../constants/colors";

const Tooltip = ({ children, tooltipIconStyle }) => {

return ( 
    <div>
        <img data-tip="info" data-for='tooltip' 
            src={info} alt="tooltip helper"   
            style={{ width: "1rem" }, tooltipIconStyle } 
        />
        <ReactTooltip 
            id='tooltip' 
            type='success'
            place='bottom'
            backgroundColor={feedbackFormGrey}
        >
            {children}
        </ReactTooltip>
    </div>       
    );
};
export default Tooltip;