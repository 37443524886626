import { white, feedbackFormGrey } from "../../constants/colors"

const styles = {
    adSpace: {
        width: "25%",
    },
    mobileAdSpace: {
        width: "0%",
        display:"none",
    },
    mobileWrapper: {
        width: "100%",
        display: "flex",
        flexFlow: "column",
        justifyContent: "space-between",
        boxSizing:"border-box",
        padding: "4% 5%",
    },
    mobileText: {
        width:"100%",
        fontSize: "2vmax",
        paddingTop:"5%",
    },
    mobileActionButton: {
        position:"absolute",
        bottom:"5%",
        width:"90%",
    },  
    mobileAboutUs: {
        display:"flex",
        flexDirection:"column",
        justifyContent:"flex-start",
        marginTop:"10%",
    },
    aboutUs: {
        display:"flex",
        flexDirection:"column",
        height:"80%",
        justifyContent:"space-between",
    },
    wrapper: {
        height: "86%",
        width: "56.7%",
        margin: "auto",
        backgroundColor: white,
        borderRadius: 36,
        boxShadow: "-16px -15px 36px #FFFFFF, 0px 14px 34px #D4DDED",
        display: "flex",
        flexFlow: "column",
        justifyContent: "space-between",
        padding: "3.33% 4%",
     },
    text: {
        color: feedbackFormGrey,
        width:"55%",
        fontSize: "2vmin",
        lineHeight: "1.25",   
    },
    ActionButton: {
        marginTop:"0",
    },
    
  };
  
  export default styles;
  