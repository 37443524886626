import api from "../../helpers/API"

const LogErrorToFirestore = (error)=>{
    api
    .postError(error)
    .catch((error) => {
      console.log(error);
    });
  }

  export default LogErrorToFirestore