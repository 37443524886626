import React,{ useEffect } from "react";
import BlogParagraph from "../components/BlogParagraph";
import BlogHeading from "../components/BlogHeading";
import BlogImage from "../components/BlogImage";
import Date from "../components/Date";
import Title from "../components/Title";
import image1 from "../assets/images/article-2-1.jpg";
import image2 from "../assets/images/article-2-2.jpg";
import Adna from "../assets/images/Adna.png";
import Writer from "../components/Writer";
import firebase from "../../../helpers/firebaseAnalytics";
import MetaDecorator from "../../../helpers/MetaDecorator";

const ArticleTwo = () => {
    useEffect(() => {
        firebase.analytics().logEvent(`Blog post The art of feedback visited`);
      }, []);
return (
    <div>
         <MetaDecorator title={'The art of feedback'} description={'It’s easy to give good feedback, we all know how to do that, right? Or even better to receive one. But, what about negative feedback?'}/>
        <Title>
            The art of feedback
        </Title>
        <Writer
            writerImg={Adna} 
            writerName={"Adna Alibasic"} 
            writerProfession={"Copywriter"}
        />
        <Date>
            28 July, 2021
        </Date>
        <BlogParagraph>
            Let's face it.
        </BlogParagraph>
        <BlogParagraph>
            Criticism sucks. Well, at least, for the majority of people it does.
        </BlogParagraph>
        <BlogParagraph>
            Nobody wants to hear they did a bad job after they’ve worked so hard to get
            it done as best as they can. You see, naturally, when hearing criticism our
            brain goes into survival mode. 
        </BlogParagraph>
        <BlogParagraph>
            Neuroscientists say that our brain, which is
            trying so hard to protect us, sees criticism as a threat, so no wonder it’s
            scary for us to hear or offer one.
        </BlogParagraph>
        
        <BlogImage imageSrc={image1} />

        <BlogParagraph>
            However, avoiding feedback isn’t an option either. Fact is, if done right,
            feedback can help us boost our job productivity, strengthen our skills and
            teach us more about ourselves.
        </BlogParagraph>
        <BlogParagraph>
            So, by the end of this post, you will discover what is feedback, its types, the
            importance of the feedback, and how to give it properly to get the maximum
            out of it.
        </BlogParagraph>
        <BlogParagraph>
            Shall we start?
        </BlogParagraph>
        <BlogHeading small>
            SO, WHAT IS FEEDBACK AND WHAT TYPES OF FEEDBACK DO WE HAVE?
        </BlogHeading>
        <BlogParagraph>
            Well, the simplest definition of feedback is when someone gives their opinion
            on your work performance. There are many types of feedback, but we have
            distinguished 4 main categories when it comes to constructive feedback that
            includes:
        </BlogParagraph>
        <BlogParagraph>
             1. Negative feedback - this type of feedback focuses on the negative actions
            that happened in the past and need to be avoided in the future. Let’s say
            you forgot to respond to an important client&#39;s mail and as a result, you end
            up losing him. You sure don’t want that kind of behavior to happen again
            
        </BlogParagraph>
        <BlogParagraph>
            2. Positive feedback - is the one we all like to hear. This type, unlike
            negative feedback, focuses on the things you did well in the past and should
            continue to do so. 2. Positive feedback - is the one we all like to hear. This type, unlike
            negative feedback, focuses on the things you did well in the past and should
            continue to do so. 
        </BlogParagraph>
        <BlogParagraph>
            3. Negative feedforward - this one is not necessarily about bad past
            behavior. Instead, it’s on avoiding potential unwanted behaviors. For
            example, when having a meeting avoid saying things that could potentially
            cause problems. 
        </BlogParagraph>
        <BlogParagraph>
            4. Positive feedforward - this type is similar to negative feedforward but in a
            good way. This can help an individual to get good results if he adds value
            which can benefit the meeting.
        </BlogParagraph>
        <BlogParagraph>
            Now that you know what types of feedback are out there, we can move onto
            another section and find out…
        </BlogParagraph>
        <BlogHeading small>
            … WHY FEEDBACK IS SO IMPORTANT?
        </BlogHeading>
        <BlogParagraph>
            Here are the top 5 benefits of feedback: 
        </BlogParagraph>
        <BlogParagraph>
            1. You are 10 times more motivated to work – who doesn’t like to hear a
            compliment? When someone recognizes our efforts it can boost our
            ego and force us to do even better job in the future
        </BlogParagraph>
        <BlogParagraph>
            2. It can serve as a helpful guide - If you are stuck and don’t know where
            you can improve, feedback can point you in the right direction so you
            know where your next move is going to be. 
        </BlogParagraph>
        <BlogParagraph>
            3. It helps you grow - With the feedback you need to put your thinking
            hat on, and see what kind of ideas you can come up with in order to
            improve your work. Also, it gets us out of our comfort zone and forces
            us to think outside the box to get the best possible results 
        </BlogParagraph>
        <BlogParagraph>
            4. Your team performs better- If everyone is getting proper feedback,
            that will help each team member to stay on top of their game. It also
            promotes mutual trust and respect within the team.
        </BlogParagraph>
        <BlogParagraph>
            5. Unnecessary conflicts can be avoided - With proper feedback we can
            avoid misunderstandings and other issues that could cause future
            troubles. 
        <BlogImage imageSrc={image2} />
        </BlogParagraph> 
        <BlogParagraph>
            Okay, now that we know how feedback can benefit us, let’s see what are…    
        </BlogParagraph>
        <BlogHeading small>
            …PROPER WAYS TO GIVE A FEEDBACK?
        </BlogHeading>
        <BlogParagraph>
            It’s easy to give good feedback, we all know how to do that, right? Or even
            better to receive one. But, what about negative feedback? Well, as with
            everything - good communication is the key. You don’t want to offend
            anyone, especially in the workplace. Always choose your words wisely, and
            try to communicate the problem with clarity. Don’t jump to criticize someone
            when there are many ways to say the same thing but in a more polite way.
        </BlogParagraph>
        <BlogParagraph>
            Let’s see few practical examples of how to give good constructive feedback
            without hurting anyone in the process:
        </BlogParagraph>
        <BlogParagraph>
            1. Don’t say “ I don’t like how you wrote this text about the product “ 
        </BlogParagraph>
        <BlogParagraph>
            Try with “ Thank you for putting an effort into writing this text, perhaps you
            could focus a bit more on the benefits of the product instead of the features”
        </BlogParagraph>
        <BlogParagraph>
            2. Don’t say “ I don’t like your presentation, it’s too much text on the slides”
        </BlogParagraph>
        <BlogParagraph>
            Try with “ Your presentation is very useful. However, don’t you think the
            bullet points could be a better solution instead of text? That way, audience
            can be more focused on what you are saying.            
        </BlogParagraph>
        <BlogParagraph>
            Do you see how little adjustments could change the whole direction of the conversation? You do? Great!
        </BlogParagraph>
        <BlogParagraph>
            Now, let’s explore other ways in which you could give a feedback. What if
            there is user friendly tool that can help you?
        </BlogParagraph>
        <BlogParagraph>
            Do you think that your feedback would be more honest if it’s done
            anonymously? Maybe that way you will feel more relaxed to say everything
            that’s on your mind?
        </BlogParagraph>
        <BlogParagraph>
            How about having all of your feedback stored in one place so you can take a
            look at it at any time?
        </BlogParagraph>
        <BlogParagraph>
            Well, the good news is that we might be able to help you out with this!
        </BlogParagraph>
        <BlogParagraph>
            Let us introduce you to the….
        </BlogParagraph>
        <BlogHeading small>
            …FEEDBACK EXCHANGE APP
        </BlogHeading>
        <BlogParagraph>
            Here at NeoNada, we created an app that could help you do all these things,
            and it’s completely free to use.
        </BlogParagraph>
        <BlogParagraph>
             With the Feedback exchange app, the users have a chance to easily and
            efficiently exchange feedback with each other and even stay anonymous if
            they want to, pretty cool, right?
        </BlogParagraph>
        <BlogParagraph>
            The person that gives you feedback doesn’t need to make their own profile,
            it’s enough to leave their feedback by simply typing in their email address
            and name if they want to, if they don’t, however, as we said earlier, being
            anonymous is an option too. Finally, you will be notified about the feedback
            and will have a chance to see all the feedbacks and collect them in one place.
        </BlogParagraph>
        <BlogParagraph>
            There is no doubt this kind of tool could be a game-changer for your
            company's work performance.
        </BlogParagraph>
        <BlogHeading small>
            SUMMARY
        </BlogHeading>
        <BlogParagraph>
            Okay, so whether you want to use an app or you prefer face-to-face
            interaction make sure to put feedback as a top priority in your organization.
            By doing so you are making sure you and your team are performing
            successfully on its journey to meet desired business goals.            
        </BlogParagraph>
    </div>
    
);
};

export default ArticleTwo;